import * as firestore from "firebase/firestore";

export interface Document {
    id: string;
    name: string;
    folder: string;
    status: string;
    error: string;
    summary: string;
    questions: number;
    sections: number;
    page: number;
    pages: number;
    words: number;
    progress: number;
    promptAddition: string;
    tokens: number;
    usage: number;
    createdAt: string;
    updatedAt: string;
    labels: string[];
}

export async function createDocument({ db, collection, id, data }: { db: firestore.Firestore, collection: string, id?: string, data?: {} }): Promise<string> {
    if (id) {
        const doc = firestore.doc(db, collection, id);
        await firestore.setDoc(doc, formatDocument({ ...data, createdAt: new Date() }));
        return id;
    }
    else {
        const doc = await firestore.addDoc(firestore.collection(db, collection), formatDocument({ ...data, createdAt: new Date() }));
        return doc.id;
    }
}

export async function documentExists({ db, collection, id }: { db: firestore.Firestore, collection: string, id: string }): Promise<boolean> {
    const doc = firestore.doc(db, collection, id);
    const snapshot = await firestore.getDoc(doc);
    return snapshot.exists();
}

export async function findDocument({ db, collection, key, value }: { db: firestore.Firestore, collection: string, key: string, value: unknown }): Promise<string | undefined> {
    const query = firestore.query(firestore.collection(db, collection), firestore.where("name", "==", value));
    const snapshot = await firestore.getDocs(query);
    if (!snapshot.empty) {
        const [doc] = snapshot.docs;
        return doc.id;
    }
    return undefined;
}

export async function updateDocument({ db, collection, id, data }: { db: firestore.Firestore, collection: string, id: string, data: {} }): Promise<void> {
    data = formatDocument({ ...data, updatedAt: new Date() });
    const doc = firestore.doc(db, collection, id);
    await firestore.updateDoc(doc, data);
}

export async function updateDocuments({ db, collection, ids, data }: { db: firestore.Firestore, collection: string, ids: string[], data: {} }): Promise<void> {
    data = formatDocument({ ...data, updatedAt: new Date() });
    const batch = firestore.writeBatch(db);
    for (const id of ids) {
        const doc = firestore.doc(db, collection, id);
        batch.update(doc, data);
    }
    await batch.commit();
}

/*
export async function updateDocumentArrayUnion({ collection, id, key, values }: { collection: string, id: string, key: string, values: unknown[] }): Promise<void> {
    await updateDocument({
        collection,
        id,
        data: {
            [key]: firestore.arrayUnion(...values)
        }
    });
}

export async function updateDocumentArrayRemove({ collection, id, key, values }: { collection: string, id: string, key: string, values: unknown[] }): Promise<void> {
    await updateDocument({
        collection,
        id,
        data: {
            [key]: firestore.arrayRemove(...values)
        }
    });
}

export async function deleteDocument({ collection, id }: { collection: string, id: string }): Promise<void> {
    const doc = firestore.doc(db, collection, id);
    await firestore.deleteDoc(doc);
}
*/

export function formatDocument<T = {}>(obj: T): T {
    const result = {} as Record<string, unknown>;
    const keys = Object.keys((obj as Record<string, unknown>));
    for (const key of keys)
        if ((obj as Record<string, unknown>)[key] !== undefined)
            result[key] = (obj as Record<string, unknown>)[key];
    return result as T;
}
