import React, { useState } from "react";

import {
    Box,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Stack,
    Typography,
    TextField
} from "@mui/material";


import {
    Delete as DeleteIcon,
    Edit as EditNoteIcon,
    CheckCircle as CheckIcon,
} from "@mui/icons-material"

import { StyledButton } from "../components";
import { useProfile, useUser } from "../context";

export interface Props {
    industries?: string[];
    industriesInputText?: string;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function IndustriesDialog({ industries, open, industriesInputText, setOpen }: Props) {
    const { user } = useUser();
    const { addPromptAssistant, deletePromptAssistant, updatePromptAssistant, updateText } = useProfile();
    const [editText, setEditText] = useState<string | undefined>(undefined);
    const [editingChip, setEditingChip] = useState<string | undefined>(undefined);
    const [addingIndustry, setAddingIndustry] = useState(false);
    const type = "industries";
    const textType = "industriesText";

    async function onDelete(industry: string) {
        if (user?.profile)
            await deletePromptAssistant(industry, type, user.profile);
    }

    function onChangeChip(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        setEditText(event.target.value);
    }

    async function onChangeInputText(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        if (user?.profile)
            await updateText(event.target.value, textType, user.profile)
    }

    function onSelectEditChip(industry: string | undefined) {
        setEditingChip(industry);
        setEditText(industry);
    }

    async function submitIndustryEdit(industry: string) {
        if (editText && user?.profile) {
            await updatePromptAssistant(industry, editText, type, user.profile);
            setEditingChip(undefined);
            setEditText(undefined);
        }
    }

    async function addNewIndustry() {
        if (editText && user?.profile) {
            await addPromptAssistant(editText, type, user.profile);
            setEditText(undefined);
            setAddingIndustry(false);
        }
    }

    function onClose() {
        setAddingIndustry(false);
        setEditText(undefined);
        setEditingChip(undefined);
        setOpen(false);
    }

    return (
        <Box>
            {open && (
                <>
                    <Dialog scroll="paper" maxWidth="sm" fullWidth open={open} onClose={() => setOpen(false)}>
                        <DialogTitle>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Typography variant="h6">INDUSTRIES</Typography>
                            </Stack>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Industries are part of the context of the request that the AI will get.
                            </DialogContentText>
                        </DialogContent>
                        <DialogContent>
                            <DialogContentText>
                                <Typography variant="h6">INPUT TEXT</Typography>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    value={industriesInputText}
                                    id="inputText"
                                    name="inputText"
                                    onChange={onChangeInputText}
                                />
                            </DialogContentText>
                        </DialogContent>
                        <DialogContent>
                            <Grid container direction="column" sx={{ mt: 1 }}>
                                <Grid item md={12} lg={12}>
                                    {!addingIndustry && (
                                        <Stack spacing={1.5}>
                                            {industries?.map(industry => (
                                                <Stack direction="row" spacing={1}>
                                                    {editingChip !== industry && (
                                                        <Chip
                                                            sx={{ borderRadius: 1, minWidth: 500, maxWidth: 500, whiteSpace: "normal", justifyContent: "left", bgcolor: "#EBF1F9", position: "relative" }}
                                                            label={industry}
                                                            size="medium"
                                                            deleteIcon={<DeleteIcon sx={{ position: "absolute", right: 0 }} />}
                                                            onDelete={() => onDelete(industry)}
                                                        />
                                                    )}
                                                    {editingChip === industry && (
                                                        <TextField
                                                            margin="normal"
                                                            variant="standard"
                                                            value={editText}
                                                            id="chipEdit"
                                                            multiline
                                                            name="Characater"
                                                            sx={{ minWidth: 400 }}
                                                            onChange={onChangeChip}
                                                            autoFocus
                                                        />
                                                    )}
                                                    {editingChip === industry && (
                                                        <IconButton disableRipple onClick={() => submitIndustryEdit(industry)} edge="end">
                                                            <CheckIcon sx={{ color: "#3FA2F7", mt: 2, ml: 1 }} fontSize="medium" />
                                                        </IconButton>
                                                    )}
                                                    {editingChip !== industry && (
                                                        <IconButton disableRipple onClick={() => onSelectEditChip(industry)} edge="end">
                                                            <EditNoteIcon sx={{ color: "#C3C3C3", mb: 1 }} fontSize="small" />
                                                        </IconButton>
                                                    )}
                                                    <br />
                                                    <br />
                                                </Stack>
                                            ))
                                            }
                                        </Stack>
                                    )}
                                    {addingIndustry && (
                                        <>
                                            <Typography variant="h6">ADD INDUSTRY</Typography>
                                            <TextField
                                                margin="normal"
                                                value={editText}
                                                multiline
                                                id="chipEdit"
                                                name="Characater"
                                                sx={{ minWidth: 400 }}
                                                onChange={onChangeChip}
                                                autoFocus
                                            />
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: "left" }}>
                            {!addingIndustry && (
                                <StyledButton
                                    sx={{ mb: 1, ml: 2, borderRadius: 5, bgcolor: "#3FA2F7", color: "white", minWidth: 130 }}
                                    variant="contained"
                                    onClick={() => setAddingIndustry(true)}
                                >
                                    Add option
                                </StyledButton>
                            )}
                            {addingIndustry && (
                                <StyledButton
                                    sx={{ mb: 1, ml: 2, borderRadius: 5, bgcolor: "#3FA2F7", color: "white", minWidth: 130 }}
                                    variant="contained"
                                    onClick={addNewIndustry}
                                >
                                    Save
                                </StyledButton>
                            )}
                            <StyledButton
                                sx={{ mb: 1, ml: 2, borderRadius: 5, bgcolor: "white", color: "#3FA2F7", minWidth: 130 }}
                                variant="contained"
                                onClick={onClose}
                            >
                                Close
                            </StyledButton>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </Box>
    );
}
