import { createContext, useContext, useEffect, useState } from "react";

import * as firebase from "firebase/auth";
import { auth } from "./firebase";

export type OAuthDelegate = "google" | "microsoft";

const OAuthProviders = {
  google:  new firebase.GoogleAuthProvider(),
  microsoft: new firebase.OAuthProvider("microsoft.com")
};

OAuthProviders.microsoft.setCustomParameters({ tenant: "8b013df2-509e-4052-b27e-13f1b04772b0" })

export interface AuthUser {
  uid: string;
  email: string;
  emailVerified: boolean;
  displayName: string;
  isAnonymous: boolean;
  photoURL: string;
  phone?: string;
}

type AuthFunction = (email: string, password: string) => Promise<AuthUser>;
type OAuthFunction = (delegate: OAuthDelegate) => Promise<firebase.UserCredential>;

export interface AuthState {
  user: AuthUser | null;
  login: AuthFunction;
  loginWithOAuth: OAuthFunction;
  logout: () => Promise<void>;
}
const AuthContext = createContext<AuthState>({} as AuthState);
export function useAuth() {
  return useContext(AuthContext);
}
async function login(email: string, password: string) {
  const { user } = await firebase.signInWithEmailAndPassword(auth, email, password);
  return user as AuthUser;
}
async function loginWithOAuth(delegate: OAuthDelegate) {
  const provider = OAuthProviders[delegate];
  if (provider) {
      const userCredentials = await firebase.signInWithPopup(auth, provider);
      return userCredentials;
  } else {
    throw new Error(`Unsupported OAUTH delegate "${delegate}"`);
  }
}
async function logout() {
  await auth.signOut();
  sessionStorage.clear();
}
export function AuthProvider({ children }: { children: JSX.Element }) {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((userState) => {
      setUser(userState as any);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);
  const value = {
    user,
    login,
    loginWithOAuth,
    logout,
  } as AuthState;
  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}
