export interface AnswerResult {
    ok: boolean;
}

export interface AnswerOptions {
    questions: string[];
    profile: string;
    document: string;
    filter?: string[]
    promptAddition?: string;
    tenant?: string;
    userId?: string;
}

const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Method": "POST",
    "Access-Control-Allow-headers": "*"
};

export async function answer({ questions, profile, document, filter, tenant, userId }: AnswerOptions): Promise<AnswerResult> {
    const url = `${process.env.REACT_APP_API_URL}/profile/${profile}/document/${document}/answers?tenant=${tenant}`;
    const body = {
        q: questions.join(","),
        filter: filter ? filter.join(",") : undefined,
        userId
    };
    const response = await fetch(url, { method: "POST", headers, body: JSON.stringify(body) });
    return { ok: response.ok }
}

export function downloadAnswersDocumentUrl({ profile, document, tenant }: { profile: string, document: string, tenant?: string }): string {
    return `${process.env.REACT_APP_API_URL}/profile/${profile}/document/${document}/generate?tenant=${tenant}`;
}

export function formatPageNumber(value: unknown): string {
    if (value instanceof Array)
        return value[0];
    else if (typeof value === "string")
        return value;
    else
        return "";
}

export function formatQuestionText(value: string): string {
    return value.replace(/^(\d{1,3}\.)|[-]/, "").trim();
}