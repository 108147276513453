import React from "react";

import LoginFormStructure from "./LoginFormStructure";

import {
    Box,
    TextField,
} from "@mui/material";

import { StyledLoadingButton } from "../components";
import { ArrowForward as ArrowIcon, } from "@mui/icons-material";

export interface LoginEmailFormProps {
    busy: boolean;
    email: string;
    error: string | undefined;
    onChangeEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleCloseAlert: () => void;
    handleSubmit: (event: React.ChangeEvent<HTMLFormElement>) => void;
}

export default function LoginEmailForm({ busy, email, error, onChangeEmail, handleCloseAlert, handleSubmit }: LoginEmailFormProps) {

    return (
        
        <LoginFormStructure error={error} handleCloseAlert={handleCloseAlert}>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>

                <TextField
                    margin="normal"
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    onChange={onChangeEmail}
                    autoFocus
                />

                <StyledLoadingButton
                    id="email"
                    type="submit"
                    fullWidth
                    variant="contained"
                    loading={busy}
                    disabled={!email}
                    endIcon={ <ArrowIcon fontSize="small"/> }
                    sx={{ mt: 3, mb: 2 }}
                >
                    Continue
                </StyledLoadingButton>
            </Box>
        </LoginFormStructure>
    );        
};
