import React from "react";
import { Label, useBreakpoint, useUser } from "../context";

import {
    Checkbox,
    Chip,
    FormControl,
    Grid,
    LinearProgress,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography
} from "@mui/material";

import { KeyboardArrowDown as ArrowDownIcon } from "@mui/icons-material"

export interface Props {
    selectedLabels: string[];
    labels: Label[];
    onChange: (event: SelectChangeEvent<string[]>) => void;
}

export default function LabelSelector({ selectedLabels, labels, onChange }: Props) {
    const { user } = useUser();
    const { breakpoint } = useBreakpoint();
    const labelsName = selectedLabels.map(values => {
        const name = labels.find(items => items.id === values)?.name;
        return name;
    });
    const selectedLabelsFinal = labelsName as string[];
    if (user)
        return (
            <FormControl>
                <Select
                    multiple
                    displayEmpty
                    color="primary"
                    value={selectedLabelsFinal}
                    size="small"
                    IconComponent={ArrowDownIcon}
                    onChange={(event) => onChange(event)}
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                            return (
                                <Typography variant="button" sx={{ color: "#3FA2F7" }}>
                                    Label
                                </Typography>
                            );
                        }
                        else if (selected.length >= 1) {
                            return (
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <Chip
                                            sx={{ borderRadius: 3, justifyContent: "center", width: 40, bgcolor: "#3FA2F7", color: "white" }}
                                            label={`+${selected.length}`}
                                            color="default"
                                            size="small"
                                        />
                                    </Grid>
                                </Grid>
                            );
                        }
                    }}
                    sx={{ minWidth: 95, bgcolor: "white", borderRadius: 6, "& .MuiSelect-icon": { color: "#3FA2F7"} }}
                >
                    <MenuItem disabled value="">
                        <em>Labels</em>
                    </MenuItem>
                    {labels.map(label => (
                        <MenuItem key={label.id} value={label.id}>
                            <Checkbox checked={selectedLabels.indexOf(label.id) > -1} />
                            <ListItemText sx={{ color: "#3FA2F7" }} primary={label.name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

        );
    else
        return (
            <LinearProgress sx={{ mt: 2 }} />
        );
}