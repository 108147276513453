import { useApp } from "../context";
import { useDocument } from "./hooks";

export interface Tags {
    category: string;
    items: string[];
}

export interface Tenant {
    id: string;
    name: string;
    providers: string[]
}
export function useTenant() {
    const { tenantId } = useApp();
    const [doc, loading, error] = useDocument("tenants", tenantId || "0");
    const tenant = doc ? { ...doc.data(), id: doc.id } as Tenant : undefined;

    return {
        tenant,
        loading,
        error,
    };
}
