import React, { useState, useEffect } from "react";

import {
    Alert,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from "@mui/material";

import { StyledButton } from "./StyledComponents"

export interface Props {
    message?: string;
    title?: string;
    defaultInput?: string;
    multiline?: boolean;
    rows?: number;
    width?: number;
    size?: "medium" | "small";
    placeholder?: string;
    open: boolean;
    onChange: (event: React.SyntheticEvent, value: string) => void;
    onClose: (event: React.SyntheticEvent) => void;
    onValidate?: (event: React.SyntheticEvent, value: string) => Promise<string | undefined>;
}

export default ({ message, title, defaultInput = "", open, multiline, rows, width = 400, size = "small", placeholder, onChange, onClose, onValidate }: Props) => {
    const [value, setValue] = useState("");
    const [alert, setAlert] = useState("");

    useEffect(() => {
        setValue(defaultInput);
        setAlert("");
    }, [open]);

    async function handleCommit(event: React.MouseEvent<HTMLButtonElement>) {
        if (onValidate) {
            const alert = await onValidate(event, value);
            if (alert) {
                setAlert(alert);
                return;
            }
        }
        onChange(event, value);
        onClose(event);
    }

    function handleClose(event: React.SyntheticEvent) {
        setValue(defaultInput || "");
        onClose(event);
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            {title && <DialogTitle>{title}</DialogTitle>}

            <DialogContent>
                {alert && <Alert severity="error" sx={{ mb: 2 }}>{alert}</Alert>}
                {message && <DialogContentText>{message}</DialogContentText>}
                <TextField
                    autoFocus
                    size={size}
                    multiline={multiline}
                    rows={rows}
                    placeholder={placeholder}
                    value={value}
                    onChange={event => setValue(event.target.value)}
                    sx={{ width }}
                />
            </DialogContent>

            <DialogActions>
                <StyledButton onClick={handleClose}>Cancel</StyledButton>
                <StyledButton onClick={handleCommit}>OK</StyledButton>
            </DialogActions>
        </Dialog>
    );
}
