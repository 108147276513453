import React from "react";
import LoginFormStructure from "./LoginFormStructure";

import {
    Box,
    Divider,
    TextField,
} from "@mui/material";

import { StyledLoadingButton } from "../components";
import {
    Google as GoogleIcon,
    Microsoft as MicrosoftIcon
} from "@mui/icons-material";

export interface AuthProps {
    busy: boolean;
    busyGoogle: boolean;
    error: string | undefined;
    password: string;
    providers: string[];
    onChangePassword: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleCloseAlert: () => void;
    handleSubmit: (event: React.ChangeEvent<HTMLFormElement>) => Promise<void>;
    handleOAuth: (type: "google" | "microsoft") => Promise<void>;
}

export default function AuthForm({ error, busy, busyGoogle, providers, password, onChangePassword, handleCloseAlert, handleSubmit, handleOAuth}: AuthProps) {
    return (
        <LoginFormStructure error={error} handleCloseAlert={handleCloseAlert}>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                {providers.includes("password") && (
                    <>
                        <TextField
                            margin="normal"
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            onChange={onChangePassword}
                        />

                        <StyledLoadingButton
                            id="password"
                            type="submit"
                            fullWidth
                            variant="contained"
                            loading={busy}
                            disabled={!password}
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </StyledLoadingButton>
                    </>
                )}

                {((providers.includes("password") && providers.includes("google.com")) || (providers.includes("password") && providers.includes("microsoft.com"))) && (
                    <Divider>OR</Divider>
                )}

                {providers.includes("google.com") && (
                    <StyledLoadingButton
                        id="google"
                        fullWidth
                        variant="contained"
                        loading={busyGoogle}
                        startIcon={<GoogleIcon />}
                        sx={{ mt: 3 }}
                        onClick={() => handleOAuth("google")}
                    >
                        Log in with Google
                    </StyledLoadingButton>
                )}

                {providers.includes("microsoft.com") && (
                    <StyledLoadingButton
                        id="microsoft"
                        fullWidth
                        variant="contained"
                        loading={busyGoogle}
                        startIcon={<MicrosoftIcon />}
                        sx={{ mt: 3 }}
                        onClick={() => handleOAuth("microsoft")}
                    >
                        Log in with Microsoft
                    </StyledLoadingButton>
                )}
            </Box>
        </LoginFormStructure>
    );
}