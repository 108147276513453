import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { getFirestoreInstance } from "./firebase";
import * as firestore from "firebase/firestore";
import * as firebase from "firebase/auth";
import { app } from "./firebase";

export interface AppState {
    authenticated: boolean;
    db: firestore.Firestore;
    setDb: React.Dispatch<React.SetStateAction<firestore.Firestore>>;
    userId?: string;
    sideBarOpen: boolean;
    setSideBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
    filter: string[];
    tenantId: string;
    setFilter: React.Dispatch<React.SetStateAction<string[]>>;
};

export const AppContext = React.createContext<AppState>({
    authenticated: false,
    db: getFirestoreInstance(),
    setDb: () => {},
    userId: "",
    sideBarOpen: false,
    setSideBarOpen: () => {},
    filter: [],
    tenantId: "",
    setFilter: () => {}
});

export function AppProvider({ children }: { children: React.ReactNode }) {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [sideBarOpen, setSideBarOpen] = useState(false);
    const [userId, setUserId] = useState(user?.uid);
    const [authenticated, setAuthenticated] = useState(!!user?.uid);
    const [filter, setFilter] = useState<string[]>([]);
    const [db, setDb] = useState(getFirestoreInstance());
    const [tenantId, setTenantId] = useState("");

    useEffect(() => {
        if (user?.uid) {
            setUserId(user.uid);
            setAuthenticated(true);
            (async () => {
                const userInstance = await firebase?.getAuth(app)?.currentUser?.getIdTokenResult();
                const dbInstance = getFirestoreInstance(userInstance?.claims?.tenant);
                setDb(dbInstance);
                setTenantId(userInstance?.claims?.tenant);
            })();
            (async () => {
                const doc = firestore.doc(db, "users", user.uid);
                await firestore.setDoc(doc, { lastSeen: new Date() }, { merge: true });
            })();
        }
        else {
            setUserId("");
            setAuthenticated(false);
            navigate("/login");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, navigate]);

    const value = {
        authenticated,
        db,
        setDb,
        sideBarOpen,
        setSideBarOpen,
        userId,
        filter,
        tenantId,
        setFilter
    };

    return (
        <AppContext.Provider value={value}>
            {children}
        </AppContext.Provider>
    );
}

export function useApp() {
    return useContext(AppContext);
}
