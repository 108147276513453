import { UserChatMessage } from "../context";

export interface AskResult {
    ok: boolean;
    msg?: string;
    question: string;
    answer: string;
    prompt: string;
    promptAddition: string;
    hits: ReferenceDocument[];
    section: Section;
    usage: number;
    finish_reason: string;
    elapsed: {
        embedding: number;
        query: number;
        prompt: number;
    }
    response: string;
    info: string;
    chat: ChatMessage[];
};

export interface ReferenceDocument {
    file: string;
    heading: string;
    summary: string;
    page: string;
    timestamp: string;
    score: string;
    disabled?: boolean;
    hitText: string;
    textScore: number;
}

export interface Section {
    id: string;
    heading: string;
    text: string;
    page: number;
    summary: string;
    questions: Question[];
    elapsed: number;
    usage: number
}

export interface Question {
    id: string;
    question: string;
}

export interface AssistantOptions {
    character: string;
    request: string;
    format: string;
    industries: string[];
    practices: string[];
}

export interface AskRequest {
    question: string;
    profile: string;
    userId: string;
    chat: UserChatMessage[];
    setMessage: React.Dispatch<React.SetStateAction<string>>;
    model: 35 | 40;
    conversationStyle?: string;
    temperature?: number;
    filter?: string[];
    tenant?: string;
}

export interface ChatMessage {
    role: "system" | "user" | "assistant";
    content: string
}

const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Method": "POST",
    "Access-Control-Allow-headers": "*"
};

export async function ask({ question, profile, userId, chat, conversationStyle, setMessage, model, temperature, filter, tenant}: AskRequest): Promise<AskResult | undefined> {
    const url = `${process.env.REACT_APP_API_URL}/profile/${profile}/ask?q=${encodeURIComponent(question)}&conversationStyle=${conversationStyle}&filter=${filter?.join(",")}&tenant=${tenant}&temperature=${temperature}`;
    const body = { chat, userId, model };
    const response = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(body)
    });

    const clone = response.clone();

    if (clone.body) {
        const reader = clone.body
            .pipeThrough(new TextDecoderStream())
            .getReader()
        while (true) {
            const { value, done } = await reader.read()
            if (done) break
            setMessage((prev) => prev + value);
        }
    }
    if (!response.ok) {
        return { ...await response.json() as AskResult } as AskResult; // todo: should throw instead
    }
    const responseText = await response.text();
    const responseJSon = JSON.parse(responseText.substring(responseText.indexOf("{")));
    const data = { ...responseJSon as AskResult, ok: true };
    return data;
}

export async function promptAssistant(profile: string, userId: string, assistant: AssistantOptions, tenant?: string): Promise<AskResult | undefined> {
    const url = `${process.env.REACT_APP_API_URL}/profile/${profile}/prompt/assistant?tenant=${tenant}`;
    const body = { assistant, userId };
    const response = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(body)
    });

    if (!response.ok)
        return { ...await response.json() as AskResult } as AskResult; // todo: should throw instead
    const data = { ...await response.json() as AskResult, ok: true };
    return data;
}