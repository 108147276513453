import React from "react";
import { useLocation, Link } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { useBreakpoint, useUser } from "./context";

import {
    Avatar,
    Box,
    Button,
    Container,
    IconButton,
    styled,
    Tab,
    Tabs,
    Toolbar,
    Tooltip,
} from "@mui/material";

import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';

import {
    Menu as MenuIcon
} from "@mui/icons-material";
import { drawerWidth, drawerWidthSm } from "./ChatHistory";

export interface AppBarComponentProps {
    historyOpen?: boolean;
    drawerOpen?: boolean;
    handleDrawerOpen: () => void;
    handleDrawerClose: () => void;
}

export interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
    drawer: number;
}

const BarNew = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open, drawer }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawer}px)`,
        marginLeft: `${drawer}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export default function AppBar({ historyOpen, drawerOpen, handleDrawerOpen, handleDrawerClose }: AppBarComponentProps) {
    const location = useLocation();
    const { logout } = useAuth();
    const { user, profile } = useUser();
    const { breakpoint } = useBreakpoint();

    const tab = (function () {
        if (location.pathname === "/" || location.pathname === "/login")
            return 0;
        else if (location.pathname.startsWith("/documents/requests"))
            return 1;
        else if (location.pathname.startsWith("/documents/responses"))
            return 2;
        else if (location.pathname.startsWith("/admin"))
            return 3;
        else
            return -1;
    })();

    async function handleLogout(): Promise<void> {
        handleDrawerClose();
        await logout();
    }

    if (user)
        return (
            <BarNew
                color="inherit"
                position="relative"
                elevation={1}
                open={drawerOpen}
                drawer={breakpoint === "sm" ? drawerWidthSm : drawerWidth}
            >
                <Container maxWidth={breakpoint}>
                    <Toolbar disableGutters>
                        <Box sx={{ flexGrow: 1, display: { xl: "flex", sm: "flex", md: 'flex', lg: "flex", xs: "contents" } }}>
                            {!drawerOpen && (
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={handleDrawerOpen}
                                    edge="start"
                                    sx={{ mr: 2, ...(historyOpen && { display: "none" }) }}
                                >
                                    <MenuIcon />
                                </IconButton>
                            )}
                        </Box>
                        <Box sx={{ flexGrow: 1, display: { xl: "flex", sm: "flex", md: 'flex', lg: "flex", xs: "contents" } }}>
                            <Tabs variant="scrollable">
                                <Tab label={<b>HOME</b>} value={0} component={Link} to="/" />
                                {profile?.requests?.enabled && <Tab label={<b>REQUESTS</b>} value={1} component={Link} to="/documents/requests/" />}
                                <Tab label={<b>INDEX</b>} value={2} component={Link} to="/documents/responses/" />
                                {user.admin && <Tab label={<b>ADMIN</b>} value={3} component={Link} to="/admin/" />}
                            </Tabs>
                        </Box>
                        <Box sx={{ flexGrow: 0 }}>
                            {user && (
                                <Button
                                    variant="text"
                                    onClick={handleLogout}
                                    startIcon={
                                        <Tooltip title="Sign out">
                                            <Avatar sx={{ bgcolor: "#1C1E2A" }}>{`${user.name ? user?.name?.split(" ")[0][0] + user?.name?.split(" ")[1][0] : "IK"}`}</Avatar>
                                        </Tooltip>
                                    }
                                />
                            )}
                        </Box>
                    </Toolbar>
                </Container>
            </BarNew>
        );
    else
        return null;
};
