import { updateDocuments } from "../firestore";
import * as firestore from "firebase/firestore";

export interface HideQuestionsOptions {
    db: firestore.Firestore;
    profile: string;
    docId: string;
    questions: string[];
}

export async function hideQuestions({ db, profile, docId, questions }: HideQuestionsOptions): Promise<void> {
    await updateDocuments({
        db,
        collection: `/profiles/${profile}/documents/${docId}/questions`,
        ids: questions,
        data: { hidden: true }
    });
}
