

const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Method": "POST",
    "Access-Control-Allow-headers": "*"
};

export async function createTenant(profile: string, tenant: string, providers: string[]): Promise<void> {
    const url = `${process.env.REACT_APP_API_URL}/profile/${profile}/tenant/${tenant}/admin/create`;
    const body = { providers };
    await fetch(url, { method: "POST", headers, body: JSON.stringify(body) });
}