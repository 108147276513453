import React from "react";

import {
    Box,
    Modal
} from "@mui/material";

export interface Props {
    open: boolean;
    file: string;
    page?: string;
    handleClose: () => void;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    height: "90%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

export default ({ file, page, open, handleClose }: Props) => (
    <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
            <object data={page ? `${file}#page=${page}` : file} type="application/pdf" width="100%" height="100%" aria-label="modal" />
        </Box>
    </Modal>
);
