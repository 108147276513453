import React, { useState } from "react";
import { useApp, useUser } from "./context";
import { answer } from "./lib";

import {
    Badge,
    SxProps,
    Theme,
    Typography
} from "@mui/material";

import { StyledLoadingButton } from "../components";

export interface Props {
    docId: string;
    questions: string[];
    onChange: (event: React.SyntheticEvent) => void;
    onError: (event: React.SyntheticEvent, error: string) => void;
    sx?: SxProps<Theme>;
}

export default function ({ docId, questions, onChange, onError, sx }: Props) {
    const { filter } = useApp();
    const { user } = useUser();
    const [busy, setBusy] = useState(false);

    async function handleAnswer(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        setBusy(true);
        const { ok } = await answer({
            profile: user?.profile || "",
            document: docId,
            questions,
            filter,
            tenant: user?.tenant,
            userId: user?.id
        });
        setBusy(false);
        if (ok)
            onChange(event);
        else
            onError(event, "Unable to process request, please try again or contact support for assistance.");
    }

    return (
        <Badge badgeContent={questions.length} color="primary" sx={sx}>
            <StyledLoadingButton
                variant="contained"
                color="secondary"
                loading={busy}
                disabled={questions.length === 0}
                onClick={handleAnswer}
            >
                <Typography>Answer Selected Questions</Typography>
            </StyledLoadingButton>
        </Badge>
    );
}
