import React, { useState } from "react";
import { useProfile, useUser } from "../context";

import {
    IconButton,
    Chip,
    Checkbox,
    Grid,
    ListItemText,
    LinearProgress,
    MenuItem,
    Select,
    SelectChangeEvent
} from "@mui/material";

import {
    Edit as EditNoteIcon,
    Cancel as CancelIcon,
    KeyboardArrowDown as ArrowDownIcon
} from "@mui/icons-material"

import IndustriesDialog from "./IndustriesDialog";
import { getMinWidth } from "./PromptAssistant";

export interface Props {
    selectedIndustries: string[];
    breakpoint: "xs" | "sm" | "md" | "lg" | "xl" | undefined;
    setSelectedIndustries: React.Dispatch<React.SetStateAction<string[]>>
    onChange: (event: SelectChangeEvent<string[]>) => void;
}

export default function IndustriesSelector({ selectedIndustries, breakpoint, setSelectedIndustries, onChange }: Props) {
    const { user } = useUser();
    const { getProfile } = useProfile();
    const profile = getProfile(user?.profile);
    const industries = profile?.promptAssistant?.industries;
    const inputText = profile?.promptAssistant?.industriesText;
    const [showEditDialog, setShowEditDialog] = useState(false);

    function onDeleteChip(item: string): void {
        setSelectedIndustries(selectedIndustries.filter(c => c !== item));
    }

    if (user)
        return (
            <>
                <Select
                    multiple
                    value={selectedIndustries}
                    size="small"
                    onChange={(event) => onChange(event)}
                    IconComponent={ArrowDownIcon}
                    disabled={!industries || industries?.length <= 0}
                    renderValue={(selected: string[]) =>
                        <Grid container spacing={2}>
                            {selected.map(item => (
                                <Grid item>
                                    <Chip
                                        sx={{ borderRadius: "20px", justifyContent: "left", bgcolor: "#EBF1F9", whiteSpace: "normal" }}
                                        label={item}
                                        onDelete={() => onDeleteChip(item)}
                                        deleteIcon={
                                            <CancelIcon
                                                onMouseDown={(event) => event.stopPropagation()}
                                            />
                                        }
                                        size="small"
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    }
                    sx={{ minWidth: getMinWidth(breakpoint, false), maxWidth: 650, bgcolor: "white", borderRadius: 6, "& .MuiSelect-icon": { color: "#3FA2F7" } }}
                >
                    {industries?.map(industry => (
                        <MenuItem key={industry} value={industry} sx={{ maxWidth: 650, whiteSpace: "normal" }}>
                            <Checkbox checked={selectedIndustries.indexOf(industry) > -1} />
                            <ListItemText sx={{ color: "#3FA2F7" }} primary={industry} />
                        </MenuItem>
                    ))}
                </Select>
                <IconButton disableRipple onClick={() => setShowEditDialog(true)} edge="end">
                    <EditNoteIcon sx={{ color: "#C3C3C3", mb: 1 }} fontSize="small" />
                </IconButton>
                <IndustriesDialog industries={industries} open={showEditDialog} industriesInputText={inputText} setOpen={setShowEditDialog} />
            </>
        );
    else
        return (
            <LinearProgress sx={{ mt: 2 }} />
        );
}