
import React from "react";

import {
    ButtonGroup,
    Stack,
    Typography
} from "@mui/material";

import { StyledButton } from "./StyledComponents";

export type ConversationStyleType = "creative" | "balanced" | "precise";

export interface ConversationStyleProps {
    conversationStyle: ConversationStyleType | undefined;
    onClick: (style: ConversationStyleType) => void;
}

export default function ConversationStyle({ conversationStyle, onClick }: ConversationStyleProps) {
    return (
        <Stack justifyContent="center" alignItems="center" spacing={2}>
        <Typography sx={{ fontSize: "15px" }} variant="body2"><b>Choose a conversation style</b></Typography>
        <ButtonGroup variant="outlined">
            <StyledButton
                sx={{
                    bgcolor: conversationStyle === "creative" ? "#3FA2F7" : "white",
                    color: conversationStyle === "creative" ? "white" : "#3FA2F7",
                    width: 100

                }}
                onClick={() => onClick("creative")}
            >
                Creative
            </StyledButton>
            <StyledButton
                sx={{
                    bgcolor: conversationStyle === "balanced" ? "#3FA2F7" : "white",
                    color: conversationStyle === "balanced" ? "white" : "#3FA2F7",
                    width: 100

                }}
                onClick={() => onClick("balanced")}
            >
                Balanced
            </StyledButton>
            <StyledButton
                sx={{
                    bgcolor: conversationStyle === "precise" ? "#3FA2F7" : "white",
                    color: conversationStyle === "precise" ? "white" : "#3FA2F7",
                    width: 100
                }}
                onClick={() => onClick("precise")}
            >
                Precise
            </StyledButton>
        </ButtonGroup>
    </Stack>
    );
}
