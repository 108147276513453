import React, { useState, useEffect } from "react";

import {
    Box,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    IconButton,
    Slider,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography
} from "@mui/material";

import {
    SmartToy as RobotIcon,
} from "@mui/icons-material"

import { CloudConfig, useAdmin, useDocument, useUser } from "../context";
import { StyledButton, StyledLoadingButton } from "./StyledComponents";

export interface ConversationStyleDialogProps {
    open: boolean;
    model: 35 | 40;
    setModel: React.Dispatch<React.SetStateAction<35 | 40>>;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

type ConversationStyleTab = "creative" | "balanced" | "precise";

export default function ConversationStyleDialog({ open, model, setModel, setOpen }: ConversationStyleDialogProps) {
    const { baseConfig, configureChatSettings, updateChatMode, updateModePrompt, updatePromptTokens, updatePrompt } = useAdmin();
    const { user, updateAiModel } = useUser();
    const [showTab, setShowTab] = useState<ConversationStyleTab>("creative");
    const [tab, setTab] = useState<number>(0)
    const [busy, setBusy] = useState(false);
    const [profileCloudDoc] = useDocument(`profiles/${user?.profile}/config`, "cloud");
    const profileCloud = profileCloudDoc ? { ...profileCloudDoc.data() } as CloudConfig : undefined;

    async function resetPrompts() {
        setBusy(true);
        const configDoc = "cloud";
        const promptAdmin = user?.profile;
        const resetProps = { persona: "", prompt: "", additionalPrompts: [] as string[] };
        if (configDoc === "cloud") {
            resetProps.persona = baseConfig?.cloud?.ask?.persona;
            resetProps.prompt = baseConfig?.cloud?.ask?.prompt;
            resetProps.additionalPrompts = baseConfig?.cloud?.ask?.additionalPrompts
        }
        if (promptAdmin) {
            await updatePromptTokens(promptAdmin, configDoc, showTab as string, resetProps.additionalPrompts)
            await updatePrompt(promptAdmin, configDoc, showTab as string, resetProps.persona, resetProps.prompt);
        }
        setBusy(false);
    }

    async function onChangePrompt(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, prompt: "strictModePrompt" | "exactTextModePrompt") {
        if (user?.profile)
            await updateModePrompt(user.profile, event.target.value, prompt)
    }

    async function onChangeTemperature(event: any) {
        if (user?.profile)
            await updateChatMode(user.profile, event.target.value, showTab, "temperature")
    }

    async function onChangeChatMode(value: boolean, field: "strictMode" | "exactTextMode") {
        if (user?.profile)
            await updateChatMode(user.profile, value, showTab, field)
    }

    function valuetext(value: number) {
        return `${value}`;
    }

    function handleTab(event: React.SyntheticEvent, newValue: number) {
        setTab(newValue);
    }

    async function onUpdateAIModel(model: 35 | 40): Promise<void> {
        setModel(model);
        await updateAiModel(model);
    }

    async function defaultConfig() {
        if (user?.profile) {
            setBusy(true);
            const resetProps = { persona: "", prompt: "", additionalPrompts: [] as string[] } 
            resetProps.persona = baseConfig?.cloud?.ask?.persona;
            resetProps.prompt = baseConfig?.cloud?.ask?.prompt;
            resetProps.additionalPrompts = baseConfig?.cloud?.ask?.additionalPrompts;
            await configureChatSettings(user?.profile);
            await resetPrompts();
            setBusy(false);
        }
    }

    return (
        <Box>
            <Dialog scroll="paper" maxWidth="lg" fullWidth open={open} onClose={() => setOpen(false)}>
                <DialogTitle>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="h5">Conversation Style Profiles</Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="h6">Strict Mode Prompt</Typography>
                        <TextField
                            margin="normal"
                            fullWidth
                            multiline
                            value={profileCloud?.ask?.strictModePrompt}
                            id="strictText"
                            name="strictText"
                            onChange={(event) => onChangePrompt(event, "strictModePrompt")}
                        />
                        <Typography variant="h6">Exact Text Mode Prompt</Typography>
                        <TextField
                            margin="normal"
                            fullWidth
                            multiline
                            value={profileCloud?.ask?.exactTextModePrompt}
                            id="exactText"
                            name="exactText"
                            onChange={(event) => onChangePrompt(event, "exactTextModePrompt")}
                        />
                    </DialogContentText>
                    <Typography variant="h6">AI Model</Typography>
                    <br />
                    <Stack spacing={1} direction="row">
                    <StyledButton sx={{ bgcolor: model === 35 ? "#3FA2F7" : "white" }} onClick={() => onUpdateAIModel(35)}>
                        <RobotIcon sx={{ color: model === 35 ? "white" : "#3FA2F7" }} fontSize="small" />
                        <Typography sx={{ ml: 0.5, color: model === 35 ? "white" : "#3FA2F7" }} variant="button">3.5</Typography>
                    </StyledButton>
                    <StyledButton sx={{ bgcolor: model === 40 ? "#3FA2F7" : "white" }} onClick={() => onUpdateAIModel(40)}>
                        <RobotIcon sx={{ color: model === 40 ? "white" : "#3FA2F7" }} fontSize="small" />
                        <Typography sx={{ ml: 0.5, color: model === 40 ? "white" : "#3FA2F7" }} variant="button">4.0</Typography>
                    </StyledButton>
                    </Stack>
                </DialogContent>
                <Tabs value={tab} onChange={handleTab}>
                    <Tab label="Creative" value={0} onClick={() => setShowTab("creative")} />
                    <Tab label="Balanced" value={1} onClick={() => setShowTab("balanced")} />
                    <Tab label="Precise" value={2} onClick={() => setShowTab("precise")} />
                </Tabs>
                <DialogContent>
                    {showTab === "creative" && (
                        <Box>
                            <Typography sx={{ fontSize: "16px" }} variant="button" gutterBottom>
                                AI Temperature
                            </Typography>
                            <Slider
                                getAriaValueText={valuetext}
                                step={0.1}
                                min={0}
                                max={1}
                                value={profileCloud?.ask?.conversationProfiles?.creative?.temperature}
                                valueLabelDisplay="on"
                                onChange={onChangeTemperature}
                            />
                            <FormControlLabel
                                label={<Typography variant="button">Strict Mode</Typography>}
                                onClick={() => onChangeChatMode(!profileCloud?.ask?.conversationProfiles?.creative?.strictMode, "strictMode")}
                                control={<Checkbox color="info" checked={profileCloud?.ask?.conversationProfiles?.creative?.strictMode} />}
                            />
                            <FormControlLabel
                                label={<Typography variant="button">Exact Text Mode</Typography>}
                                onClick={() => onChangeChatMode(!profileCloud?.ask?.conversationProfiles?.creative?.exactTextMode, "exactTextMode")}
                                control={<Checkbox color="info" checked={profileCloud?.ask?.conversationProfiles?.creative?.exactTextMode} />}
                            />
                        </Box>
                    )}
                    {showTab === "balanced" && (
                        <Box>
                            <Typography sx={{ fontSize: "16px" }} variant="button" gutterBottom>
                                AI Temperature
                            </Typography>
                            <Slider
                                getAriaValueText={valuetext}
                                step={0.1}
                                min={0}
                                max={1}
                                value={profileCloud?.ask?.conversationProfiles?.balanced?.temperature}
                                valueLabelDisplay="on"
                                onChange={onChangeTemperature}
                            />
                            <FormControlLabel
                                label={<Typography variant="button">Strict Mode</Typography>}
                                onClick={() => onChangeChatMode(!profileCloud?.ask?.conversationProfiles?.balanced?.strictMode, "strictMode")}
                                control={<Checkbox color="info" checked={profileCloud?.ask?.conversationProfiles?.balanced?.strictMode} />}
                            />
                            <FormControlLabel
                                label={<Typography variant="button">Exact Text Mode</Typography>}
                                onClick={() => onChangeChatMode(!profileCloud?.ask?.conversationProfiles?.balanced?.exactTextMode, "exactTextMode")}
                                control={<Checkbox color="info" checked={profileCloud?.ask?.conversationProfiles?.balanced?.exactTextMode} />}
                            />
                        </Box>
                    )}
                    {showTab === "precise" && (
                        <Box>
                            <Typography sx={{ fontSize: "16px" }} variant="button" gutterBottom>
                                AI Temperature
                            </Typography>
                            <Slider
                                getAriaValueText={valuetext}
                                step={0.1}
                                min={0}
                                max={1}
                                value={profileCloud?.ask?.conversationProfiles?.precise?.temperature}
                                valueLabelDisplay="on"
                                onChange={onChangeTemperature}
                            />
                            <FormControlLabel
                                label={<Typography variant="button">Strict Mode</Typography>}
                                onClick={() => onChangeChatMode(!profileCloud?.ask?.conversationProfiles?.precise?.strictMode, "strictMode")}
                                control={<Checkbox color="info" checked={profileCloud?.ask?.conversationProfiles?.precise?.strictMode} />}
                            />
                            <FormControlLabel
                                label={<Typography variant="button">Exact Text Mode</Typography>}
                                onClick={() => onChangeChatMode(!profileCloud?.ask?.conversationProfiles?.precise?.exactTextMode, "exactTextMode")}
                                control={<Checkbox color="info" checked={profileCloud?.ask?.conversationProfiles?.precise?.exactTextMode} />}
                            />
                        </Box>
                    )}
                </DialogContent>
                <DialogActions sx={{ justifyContent: "right" }}>
                    <StyledLoadingButton
                        sx={{ mb: 1, minWidth: 130 }}
                        variant="outlined"
                        onClick={defaultConfig}
                        loading={busy}
                    >
                        Get Default
                    </StyledLoadingButton>
                    <StyledButton
                        sx={{ mb: 1, minWidth: 130 }}
                        variant="contained"
                        onClick={() => setOpen(false)}
                    >
                        Ok
                    </StyledButton>
                </DialogActions>
            </Dialog>
        </Box>
    );
}