import React, { useState } from "react";
import { useProfile } from "./context";

import {
    Box,
    IconButton,
    MenuList,
    MenuItem,
    Stack,
    Typography
} from "@mui/material";

import {
    AddBox as AddIcon,
    DeleteOutline as DeleteIcon
} from "@mui/icons-material";

import {
    ConfirmDialog,
    InputDialog
} from "./components";

export interface Props {
    open: boolean;
    onClose: (event: React.SyntheticEvent) => void;
}

export default function ManageProfileDialog({ open, onClose }: Props) {
    const { profiles, addProfile, deleteProfile } = useProfile();
    const [inputDialogOpen, setInputDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [confirmDeleteProfile, setConfirmDeleteProfile] = useState("");

    function handleAddProfile(event: React.SyntheticEvent) {
        setInputDialogOpen(true);
    }

    function handleDeleteProfile(event: React.SyntheticEvent, profile: string) {
        event.stopPropagation();
        setDeleteDialogOpen(true);
        setConfirmDeleteProfile(profile);
    }

    async function handleCommitAddProfile(event: React.SyntheticEvent, profile: string) {
        await addProfile(profile);
    }

    async function handleConfirmDeleteProfile(event: React.SyntheticEvent, confirmed: boolean) {
        if (confirmed) {
            await deleteProfile(confirmDeleteProfile);
            setConfirmDeleteProfile("");
        }
        setDeleteDialogOpen(false);
    }

    return (
        <Box>
            {open && (
                <>
                    <Stack sx={{ ml: 2 }}>
                        <Stack direction="row" spacing={31.5} alignItems="center">
                            <Typography sx={{ ml: 2 }} variant="h6">Manage Collections</Typography>
                            <IconButton edge="end" onClick={handleAddProfile}>
                                <AddIcon fontSize="small" />
                            </IconButton>
                        </Stack>
                        <MenuList>
                            {profiles.map(profile => (
                                <MenuItem key={profile.id} disableRipple>
                                    <Typography display="block" width={400}>{profile.name}</Typography>
                                    <IconButton edge="end" onClick={event => handleDeleteProfile(event, profile.id)}>
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Stack>
                </>
            )}
            <InputDialog
                title="Add collection"
                open={inputDialogOpen}
                onChange={(event, value) => handleCommitAddProfile(event, value)}
                onClose={() => setInputDialogOpen(false)}
            />
            <ConfirmDialog
                title="Delete collection"
                message="Are you sure you want to delete this collection?"
                open={deleteDialogOpen}
                onClose={(event, confirmed) => handleConfirmDeleteProfile(event, confirmed)}
            />
        </Box>
    );
}
