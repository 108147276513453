import React, { useState } from "react";
import ManageProfilesDialog from "./ManageProfilesDialog";
import ManageUsersDialog from "./ManageUsersDialog";
import ManageTenants from "./ManageTenantsDialog";
import * as firestore from "firebase/firestore";

import {
    Backdrop,
    CircularProgress,
    Stack,
    Tab,
    Tabs,
    Paper,
    styled
} from "@mui/material";

import {
    AccountCircle as UserIcon,
    Assignment as PromptIcon,
    FolderCopy as CollectionIcon,
    Business as TenantIcon,
} from "@mui/icons-material"

import { Tenant, useApp, useUser } from "../context";
import { FirebaseUser, getUsers } from "../lib";
import Prompts from "./Prompts";

const admins = ["jason@ikaun.com", "dtempx@gmail.com", "jason.r.noble@gmail.com", "bcdias96@gmail.com", "breno.dias@ikaun.com", "dtemp@mailfence.com"];

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary
}));

export default function Admin() {
    const { db } = useApp();
    const { user } = useUser();
    const [open, setOpen] = useState(true);
    const [showUserDialog, setShowUserDialog] = useState(false);
    const [showPromptDialog, setShowPromptDialog] = useState(false);
    const [showTenantDialog, setShowTenantDialog] = useState(false);
    const [tab, setTab] = useState<number>(0);
    const [users, setUsers] = useState<FirebaseUser[]>();
    const [tenants, setTenants] = useState<Tenant[]>([]);
    const [loading, setLoading] = useState(false);

    async function onClickManageUsers() {
        setLoading(true);
        setOpen(false);
        setShowPromptDialog(false);
        setShowTenantDialog(false);
        const response = await getUsers(user?.profile, user?.tenant);
        await getTenantConfig();
        setUsers(response);
        setShowUserDialog(true);
        setLoading(false);
    }

    async function getTenantConfig() {
        const query = firestore.query(firestore.collection(db, "tenants"));
        const snapshot = await firestore.getDocs(query);
        snapshot.forEach(doc => {
            const data = { ...doc.data() } as Tenant;
            tenants.push(data);
        })
    }

    function onClickManageProfiles() {
        setOpen(true)
        setShowPromptDialog(false)
        setShowUserDialog(false)
        setShowTenantDialog(false)
        setTenants([]);
    }

    function onClickManagePrompts() {
        setShowPromptDialog(true)
        setOpen(false)
        setShowUserDialog(false)
        setShowTenantDialog(false)
        setTenants([]);
    }

    function onClickManageTenants() {
        setShowTenantDialog(true)
        setShowPromptDialog(false)
        setOpen(false)
        setShowUserDialog(false)
        setTenants([]);
    }

    function handleChange(event: React.SyntheticEvent, newValue: number) {
        setTab(newValue);
    }

    return (
        <Stack direction="row" justifyContent="center" spacing={4}>
            <Item elevation={0}>
                <Tabs value={tab} orientation="vertical" onChange={handleChange}>
                    <Tab label={<div><CollectionIcon style={{ verticalAlign: 'middle' }} /> Collections</div>} value={0} onClick={onClickManageProfiles} />
                    <Tab label={<div><UserIcon style={{ verticalAlign: 'middle' }} /> Users</div>} value={1} onClick={onClickManageUsers} />
                    <Tab label={<div><PromptIcon style={{ verticalAlign: 'middle' }} /> Prompts</div>} value={2} onClick={onClickManagePrompts} />
                    {user?.email && admins.includes(user?.email) && (<Tab label={<div><TenantIcon style={{ verticalAlign: 'middle' }} /> Tenants</div>} value={3} onClick={onClickManageTenants} />)}
                </Tabs>
            </Item>
            <Item elevation={0}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <ManageProfilesDialog
                    open={open}
                    onClose={() => setOpen(false)}
                />
                <ManageUsersDialog
                    users={users}
                    open={showUserDialog}
                    tenants={tenants}
                    onClose={() => {
                        setTenants([]);
                        setShowUserDialog(false)
                    }}
                />
                <ManageTenants
                    open={showTenantDialog}
                    setOpen={setShowTenantDialog}
                    onClose={() => setShowTenantDialog(false)}
                />
                {user && <Prompts user={user} open={showPromptDialog} />}
            </Item>
        </Stack>
    );
}