import { useCollection } from "./hooks";
import { useApp, useUser, useAdmin } from "../context";
import * as firestore from "firebase/firestore";

import {
    createDocument,
    findDocument,
    updateDocument,
    formatDocument
} from "../lib";

interface PromptAssistant {
    characters: string[];
    characterText: string | undefined;
    output: string[];
    outputText: string | undefined;
    requests: string[];
    requestsText: string | undefined;
    practices: string[];
    practicesText: string | undefined;
    industries: string[];
    industriesText: string | undefined;
}

export interface Profile {
    id: string;
    name: string;
    color?: string;
    deletedAt?: Date;
    promptAssistant?: PromptAssistant;
}

export type PromptAssistantFields = "characters" | "requests" | "output" | "practices" | "industries";
export type PromptAssistantTextFields = "characterText" | "outputText" | "requestsText" | "practicesText" | "industriesText";

export function useProfile() {
    const { setUserProfile } = useUser();
    const { baseConfig, initialSetup, configureChatSettings } = useAdmin();
    const { db } = useApp();
    const [collection, loading, error] = useCollection("/profiles");

    const profiles = collection?.docs
        .map(doc => ({ ...formatDocument(doc.data()), id: doc.id } as Profile))
        .filter(doc => !doc.deletedAt)
        .sort((a, b) => a.name?.localeCompare(b.name)) || [];

    const i = profiles.findIndex(profile => profile.id === "default");
    if (i === -1)
        profiles.unshift({ id: "default", name: "(Default)" });
    else if (i !== 0)
        profiles.unshift(profiles.splice(i, 1)[0]);

    for (const profile of profiles)
        if (!profile.name)
            profile.name = profile.id;

    async function addProfile(name: string): Promise<string> {
        let id = await findDocument({ db, collection: "profiles", key: "name", value: name });
        if (!id) {
            id = await createDocument({ db, collection: "profiles", id: name, data: { name } });
            await initialSetup(name, baseConfig?.cloud, "cloud");
            await initialSetup(name, baseConfig?.importer, "importer");
            await configureChatSettings(name);
        }
        else
            await updateDocument({ db, collection: "profiles", id, data: { deletedAt: undefined } });
        setUserProfile(id);
        return id;
    }

    async function deleteProfile(id: string): Promise<void> {
        debugger;
        if (id !== "default") {
            await updateDocument({ db, collection: "profiles", id, data: { deletedAt: new Date() } });
            setUserProfile(profiles[0].id);
        }
    }

    async function addPromptAssistant(value: string, type: PromptAssistantFields, profileName: string) {
        if (profileName) {
            const profile = profiles.find(item => item.name === profileName)
            if (profile?.promptAssistant) {
                const promptAssistant = profile.promptAssistant;
                if (promptAssistant[type])
                    promptAssistant[type] = [...promptAssistant[type], value];
                else
                    promptAssistant[type] = [value]

                const doc = firestore.doc(db, "profiles", profileName);
                await firestore.setDoc(doc, { promptAssistant }, { merge: true });
            }
            else {
                const promptAssistant = { [type]: [value] };
                const doc = firestore.doc(db, "profiles", profileName);
                await firestore.setDoc(doc, { promptAssistant }, { merge: true });
            }
        }
    }

    async function updatePromptAssistant(value: string, newValue: string, type: PromptAssistantFields, profileName: string) {
        if (profileName) {
            const profile = profiles.find(item => item.name === profileName)
            if (profile?.promptAssistant) {
                const promptAssistant = profile.promptAssistant;
                const index = profile.promptAssistant[type].findIndex(i => i === value);
                promptAssistant[type][index] = newValue;

                const doc = firestore.doc(db, "profiles", profileName);
                await firestore.setDoc(doc, { promptAssistant }, { merge: true });
            }
        }
    }

    async function deletePromptAssistant(value: string, type: PromptAssistantFields, profileName: string) {
        if (profileName) {
            const profile = profiles.find(item => item.name === profileName)
            if (profile?.promptAssistant) {
                const promptAssistant = profile.promptAssistant;
                promptAssistant[type] = promptAssistant[type].filter(item => item !== value);

                const doc = firestore.doc(db, "profiles", profileName);
                await firestore.setDoc(doc, { promptAssistant }, { merge: true });
            }
        }
    }

    async function updateText(value: string, type: PromptAssistantTextFields, profileName: string) {
        if (profileName) {
            const profile = profiles.find(item => item.name === profileName)
            if (profile?.promptAssistant) { 
                const doc = firestore.doc(db, "profiles", profileName);
                await firestore.setDoc(doc, { promptAssistant: { [type]: value.length >= 1 ? value : firestore.deleteField() } }, { merge: true });
            }
        }
    }

    function getProfile(profileName?: string): Profile | undefined {
        const profile = profiles.find(item => item.name === profileName);
        return profile;
    }

    return {
        profiles,
        addProfile,
        addPromptAssistant,
        deleteProfile,
        deletePromptAssistant,
        getProfile,
        updatePromptAssistant,
        updateText,
        loading,
        error
    };
}
