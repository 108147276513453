import React, { useState } from "react";
import { Profile, useProfile, useUser } from "../context";

import {
    LinearProgress,
    MenuItem,
    Select,
    SelectChangeEvent
} from "@mui/material";

export interface Props {
    onChange: (event: SelectChangeEvent<string>, setValue: React.Dispatch<React.SetStateAction<string | undefined>>) => void
    extraProfile?: Profile;
    userProfiles?: string[];
    profile?: string;
}

export default function ProfileSelector({ extraProfile, profile, userProfiles, onChange}: Props) {
    const { user } = useUser();
    const { profiles } = useProfile();
    const [value, setValue] = useState<string | undefined>(profile);
    const profilesArray = userProfiles?.map(profs => {
        return { id: profs, name: profs }
    }) as Profile[]
    const IProfiles = userProfiles ? profilesArray : extraProfile ? [extraProfile, ...profiles] : profiles;

    if (user)
        return (
            <Select
                value={value || user?.profile}
                size="small"
                defaultValue={user?.profile}
                onChange={(event) => onChange(event, setValue)}
                sx={{ minWidth: 200 }}
            >
                {IProfiles.map(profile => (
                    <MenuItem value={profile.id}>{profile.name}</MenuItem>
                ))}
            </Select>
        );
    else
        return (
            <LinearProgress sx={{ mt: 2 }} />
        );
}