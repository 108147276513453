import { useApp } from "../context";
import { useDocument } from "./hooks";
import { ImporterConfig } from "./admin";
import { ChatMessage, ReferenceDocument } from "../lib";
import * as firestore from "firebase/firestore";
import { ConversationStyleType } from "../components/ConversationStyle";

export interface UserChatMessage extends ChatMessage {
    hits?: ReferenceDocument[];
}

export interface UserChat {
    chat: UserChatMessage[];
    date: any;
    id: string;
    profile: string;
}
 
export interface User {
    id: string;
    email?: string;
    name?: string;
    admin?: boolean;
    promptAdmin?: string;
    profile?: string;
    profiles?: string[];
    chats?: UserChat[];
    labels?: string[];
    model: 35 | 40;
    tenant: string;
    tempChat: string;
    chatProcessing: number;
    conversationStyle: ConversationStyleType;
}

export function useUser() {
    const { db, userId } = useApp();
    const [doc, loading, error] = useDocument("users", userId || "0");
    const user = doc ? { ...doc.data(), id: doc.id } as User : undefined;
    const [profileDoc] = useDocument(`profiles/${user?.profile}/config`, "importer");
    const profile = profileDoc ? { ...profileDoc.data() } as ImporterConfig : undefined;

    async function setUserProfile(profile: string) {
        if (userId) {
            const doc = firestore.doc(db, "users", userId);
            await firestore.setDoc(doc, { profile, labels:firestore.deleteField()  }, { merge: true });
        }
    }

    async function setUserLabel(labels: string[]) {
        if (userId) {
            const doc = firestore.doc(db, "users", userId);
            await firestore.setDoc(doc, { labels: labels.length >= 1 ? labels : firestore.deleteField() }, { merge: true });
        }
    }

    async function setUserAdmin(promptAdmin: string) {
        if (userId) {
            const doc = firestore.doc(db, "users", userId);
            await firestore.setDoc(doc, { promptAdmin }, { merge: true });
        }
    }

    async function updateChat(chats: UserChat[]) {
        if (userId) {
            const doc = firestore.doc(db, "users", userId);
            await firestore.setDoc(doc, { chats }, { merge: true });
        }
    }

    async function updateTempChat() {
        if (userId) {
            const doc = firestore.doc(db, "users", userId);
            await firestore.setDoc(doc, { tempChat: firestore.deleteField() }, { merge: true });
        }
    }

    async function updateConversationStyle(style: ConversationStyleType) {
        if (userId) {
            const doc = firestore.doc(db, "users", userId);
            await firestore.setDoc(doc, { conversationStyle: style }, { merge: true });
        }
    }

    async function updateAiModel(model: 35 | 40): Promise<void> {
        if (userId) {
            const doc = firestore.doc(db, "users", userId);
            await firestore.setDoc(doc, { model }, { merge: true });
        }
    }

    async function purgeChatHistory(chats: UserChat[]): Promise<void> {
        if (userId) {
            const doc = firestore.doc(db, "users", userId);
            await firestore.setDoc(doc, { chats }, { merge: true });
        }
    }

    return {
        user,
        profile,
        loading,
        error,
        setUserAdmin,
        setUserProfile,
        setUserLabel,
        purgeChatHistory,
        updateAiModel,
        updateChat,
        updateConversationStyle,
        updateTempChat
    };
}
