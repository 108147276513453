import { useCollection } from "./hooks";
import { useUser } from "./user";
import { useApp } from "../AppContext";

import {
    createDocument,
    findDocument,
    updateDocument,
    formatDocument
} from "../lib";

export interface Label {
    id: string;
    name: string;
    color: string;
    deleted: boolean;
}

export function useLabels() {
    const { user } = useUser();
    const { db } = useApp();
    const [collection, loading, error] = useCollection(`/profiles/${user?.profile}/labels`);

    const labels = collection?.docs
        .map(doc => ({ ...formatDocument(doc.data()), id: doc.id } as Label))
        .filter(label => !label.deleted)
        .sort((a, b) => a.name?.localeCompare(b.name)) || [];

    async function addLabel(name: string): Promise<string> {
        const collection = `/profiles/${user?.profile}/labels`;
        let id = await findDocument({ db, collection, key: "name", value: name });
        if (!id)
            id = await createDocument({ db, collection, data: { name } });
        else
            await updateDocument({ db, collection, id, data: { deleted: false } });
        return id;
    }
    
    async function deleteLabel(id: string): Promise<void> {
        const collection = `/profiles/${user?.profile}/labels`;
        await updateDocument({ db, collection, id, data: { deleted: true } });
    }
        
    return {
        labels,
        addLabel,
        deleteLabel,
        loading,
        error
    };
}
