export interface UserRecord {
    users: FirebaseUser[];
}

export interface FirebaseUser {
    uid: string;
    displayName: string;
    email: string;
    phoneNumber: string;
    photoURL: string;
    disabled: boolean;
}

export interface CreateUser {
    profile: string;
    email: string;
    tenant: string;
    providers: string[]
    admin: boolean;
    name?: string;
    profiles?: string[];
    password?: string;
}

const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Method": "*",
    "Access-Control-Allow-headers": "*"
};

export async function createUser({ profile, email, providers, tenant, admin, name, profiles, password}:CreateUser): Promise<FirebaseUser>{
    const url = `${process.env.REACT_APP_API_URL}/profile/${profile}/admin/user/create?email=${email}&tenant=${tenant}&password=${password}`;
    const body = { providers, admin, profiles, name };
    const response = await fetch(url, { method: "POST", headers, body: JSON.stringify(body) });
    if (!response.ok)
        return {} as FirebaseUser; // todo: should throw instead
    const data = await response.json();
    return data; // todo: should throw instead
}

export async function editUser(profile: string, email: string, userId: string, admin: boolean, profiles: string[], name?: string, password?: string): Promise<boolean>{
    const url = `${process.env.REACT_APP_API_URL}/profile/${profile}/admin/user/edit?email=${email}&userId=${userId}&password=${password}`;
    const body = { admin, profiles, name };
    const response = await fetch(url, { method: "POST", headers, body: JSON.stringify(body) });
    return response.ok; // todo: should throw instead
}

export async function deleteUser(profile: string, uid: string): Promise<boolean> {
    const url = `${process.env.REACT_APP_API_URL}/profile/${profile}/admin/user/delete?uid=${uid}`;
    const response = await fetch(url, { method: "DELETE", headers });
    return response.ok; // todo: should throw instead
}

export async function getUsers(profile?: string, tenant?: string): Promise<FirebaseUser[]> {
    const url = `${process.env.REACT_APP_API_URL}/profile/${profile}/admin/users?tenant=${tenant}`;
    const response = await fetch(url, { method: "GET", headers });
    if (!response.ok)
        return [] as FirebaseUser[]; // todo: should throw instead
    const data = await response.json();
    return data.users;
}
