import { Box, Chip, IconButton, InputAdornment, TextField } from "@mui/material";

import { StyledLoadingButton } from "../components";


import { 
    Add as AddIcon,
} from "@mui/icons-material"

export interface PromptFormProps {
    persona?: string;
    prompt?: string;
    additionalPrompts?: string[];
    busy: boolean;
    tokenText: string;
    onChangeToken: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onDelete: (tokenId: string) => Promise<void>;
    onAddToken: (event: React.SyntheticEvent) => void;
    handleSubmit: (event: React.ChangeEvent<HTMLFormElement>) => Promise<void>;
}

export default function PromptForm({
    persona,
    prompt,
    additionalPrompts,
    busy,
    tokenText,
    onAddToken,
    onChangeToken,
    onChange,
    onDelete,
    handleSubmit
}: PromptFormProps) {
    return (
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
                margin="normal"
                fullWidth
                id="persona"
                label="Persona"
                name="persona"
                value={persona}
                focused
                onChange={onChange}
            />

            <TextField
                margin="normal"
                fullWidth
                multiline
                id="prompt"
                label="Base Prompt"
                name="prompt"
                value={prompt}
                onChange={onChange}
                focused
            />

            <TextField
                margin="normal"
                id="additionalPrompts_text"
                label="Additional Prompts"
                name="additionalPrompts"
                value={tokenText}
                onChange={onChangeToken}
                fullWidth
                focused
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={onAddToken} edge="end">
                                <AddIcon fontSize="medium" />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <Box>
            { additionalPrompts && additionalPrompts.length >= 1 && additionalPrompts?.map(token => (
                <>
                    <Chip
                        label={token}
                        color="info"
                        size="small"
                        onDelete={() => onDelete(token)}
                    />
                    <br/>
                </>
            ))}
            </Box>
            <br/>
            <StyledLoadingButton
                id="prompt"
                fullWidth
                type="submit"
                variant="contained"
                loading={busy}
            >
                Save
            </StyledLoadingButton>
        </Box>
    );
}