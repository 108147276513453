import React, { useState /*useEffect*/ } from "react";
import { useNavigate } from "react-router-dom";

import {
    Drawer,
    Divider,
    InputAdornment,
    IconButton,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    styled,
    SxProps,
    TextField,
    Theme,
    Tooltip,
    useTheme
} from "@mui/material";

import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';

import {
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
    QuestionAnswer as QuestionAnswerIcon,
    Search as SearchIcon,
    Chat as ChatIcon,
    Delete as DeleteIcon
} from "@mui/icons-material"

import { UserChat, useBreakpoint, useUser } from "./context";
import { StyledButton, useMediaQuery } from "./components";

export interface ChatHistoryProps {
    sx?: SxProps<Theme>
    handleDrawerOpen: () => void;
    handleDrawerClose: () => void;
    open: boolean;
}

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
}));

export const drawerWidth = 300;
export const drawerWidthSm = 205;

export default function Chat({ sx, open, handleDrawerOpen, handleDrawerClose }: ChatHistoryProps) {
    const { user, purgeChatHistory } = useUser();
    const theme = useTheme();
    const { breakpoint } = useBreakpoint();
    const navigate = useNavigate()
    const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const [filter, setFilter] = useState<string | undefined>(undefined);

    function selectFromHistory(userChat: UserChat): void {
        if (isMobile)
            handleDrawerClose()
        navigate("/", {
            state: {
                stateChatId: userChat.id,
                stateChatDate: new Date(),
                sendMessageFocus: true
            }
        })
    }

    function onClickNewChat(): void {
        if (isMobile)
            handleDrawerClose()
        navigate("/", {
            state: {
                sendMessageFocus: true,
                resetChat: true
            }
        })
    }

    function onFilter(event: React.ChangeEvent<HTMLInputElement>): void {
        setFilter(event.target.value);
    }

    async function purgeHistory(): Promise<void> {
        if (user?.chats && user?.chats?.length >= 10) {
            const chats = user.chats.slice(0, 10);
            await purgeChatHistory(chats);
        }
    }

    return (
        <Drawer
            sx={{
                maxWidth: breakpoint !== "sm" ? drawerWidth : drawerWidthSm,
                minWidth: breakpoint !== "sm" ? drawerWidth : drawerWidthSm,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    maxWidth: breakpoint !== "sm" ? drawerWidth : drawerWidthSm,
                    minWidth: breakpoint !== "sm" ? drawerWidth : drawerWidthSm,
                    boxSizing: 'border-box',
                },
            }}
            variant="persistent"
            anchor="left"
            open={open}
        >
            <MuiAppBar
                elevation={1}
                color="inherit"
                position="relative"
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon sx={{ color: "#1C1E2A" }} /> : <ChevronRightIcon sx={{ color: "#1C1E2A" }} />}
                    </IconButton>
                </DrawerHeader>
            </MuiAppBar>
            <Divider />
            <Grid spacing={2} direction="row" bgcolor="#1C1E2A">
                <Grid container spacing={2}>
                    <Grid item sm={8}>
                        <TextField
                            size="small"
                            placeholder="Search"
                            value={filter}
                            onChange={onFilter}
                            InputProps={{
                                sx: { bgcolor: "white", borderRadius: 5, mt: 2, ml: 1, maxWidth: 180 },
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon sx={{ color: "#3FA2F7" }} fontSize="medium" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item sm={1.5}>
                        <Tooltip title="New Chat">
                            <IconButton
                                disableRipple
                                onClick={onClickNewChat}
                                edge="end"
                            >
                                <ChatIcon sx={{ color: "white", mt: 2 }} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item sm={1}>
                        <Tooltip title="Purge History">

                            <IconButton
                                disableRipple
                                onClick={purgeHistory}
                                edge="end"
                            >
                                <DeleteIcon sx={{ color: "white", mt: 2 }} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid item>
                    <List id="history" sx={{ height: "100vh", overflowY: "auto" }}>
                        {user?.chats?.map(testing => (
                            ((filter && filter.length >= 1 && testing.chat[0].content.includes(filter) && testing.profile === user?.profile) || (!filter && testing.profile === user?.profile)) &&
                            <ListItemButton onClick={() => selectFromHistory(testing)} >
                                <QuestionAnswerIcon sx={{ color: "white" }} fontSize="small" />
                                <ListItem
                                    key={testing.id}
                                    sx={{ bgcolor: "#1C1E2A", lineHeight: 5 }}
                                    alignItems="flex-start"
                                >
                                    <Tooltip title={testing.chat[0].content}>
                                        <ListItemText
                                            sx={{ textAlign: "left" }}
                                            secondaryTypographyProps={{ noWrap: true, color: "white" }}
                                            secondary={testing.chat[0].content}
                                        />
                                    </Tooltip>
                                </ListItem>
                            </ListItemButton>
                        ))}
                    </List>
                </Grid>
            </Grid>
        </Drawer>
    );
}
