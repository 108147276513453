import React from "react"

import { Alert, AlertColor, AlertTitle, Snackbar, SnackbarProps } from "@mui/material"

export interface AlertPopUpProps extends SnackbarProps {
    msg: React.ReactNode | string | undefined;
    severity: AlertColor | undefined;
    title: string;
    handleClose: () => void;
}

const AlertPopUp = (props: AlertPopUpProps) => {
    return (
        <Snackbar
            open={props.open}
            autoHideDuration={props.autoHideDuration}
            anchorOrigin={props.anchorOrigin}
            onClose={props.handleClose}
        >
            <Alert
                severity={props.severity}
                onClose={props.handleClose}
            >
                <AlertTitle>{props.title}</AlertTitle>
                {props.msg}
            </Alert>
        </Snackbar>
    );
}

export default AlertPopUp;