import { collection, doc } from "firebase/firestore";
import * as hooks from "react-firebase-hooks/firestore";
import { useApp } from "../AppContext";


export function useCollection(name: string) {
    const { db } = useApp();
    return hooks.useCollection(collection(db, name), { snapshotListenOptions: { includeMetadataChanges: true }});
}

export function useDocument(path: string, id: string) {
    const { db } = useApp();
    return hooks.useDocument(doc(db, path, id), { snapshotListenOptions: { includeMetadataChanges: true }});
}
