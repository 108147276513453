import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { UserChatMessage, useApp, useCollection, useUser, useLabels, useAdmin } from "./context";
import { ask, getPDFLink } from "./lib";
import { Collapsible, PDFViewer, useMediaQuery } from "./components";

import {
    Box,
    Card,
    CardContent,
    Grid,
    InputAdornment,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemAvatar,
    Tooltip,
    Typography,
    Stack,
    SxProps,
    styled,
    TextField,
    Theme,
    SelectChangeEvent
} from "@mui/material";

import {
    ContentPaste as CopyIcon,
    Edit as EditNoteIcon,
    Assistant as AssistantIcon,
    PersonOutlined as PersonIcon,
    Send as SendIcon,
    RestartAlt as RestartIcon,
    KeyboardArrowDown as ArrowDownIcon,
    Psychology as ConversationStyleIcon,
    Settings as SettingsIcon
} from "@mui/icons-material"

import CollectionSelector from "./Admin/CollectionSelector";
import logo from "./assets/images/ikaun-logo-plain.png";
import PromptAssistantDialog from "./PromptAssistant/PromptAssistant";
import LabelSelector from "./Admin/LabelSelector";
import AlertPopUp from "./components/AlertPopUp";
import { StyledButton, StyledLoadingButton } from "./components/StyledComponents";
import TransitionDialog from "./components/TransitionDialog"
import ConversationStyle, { ConversationStyleType } from "./components/ConversationStyle";
import ConversationStyleDialog from "./components/ConversationStyleDialog";

export interface ChatStateProps {
    stateChatId: string;
    stateChatDate: Date;
    sendMessageFocus: boolean;
}

export interface Props {
    sx?: SxProps<Theme>
}


const ChatInput = styled(TextField)`
& label.Mui-focused {
  color: #3FA2F7;
}
& .MuiInput-underline:after {
    borderBottomColor: #3FA2F7;
}
& .MuiOutlinedInput-root {
  &.Mui-focused fieldset {
    border-color: #3FA2F7;
  }
  &:hover fieldset: {
    borderColor: #3FA2F7
  }
  &:hover: {
    borderColor: #3FA2F7
  }
  '& fieldset': {
    borderColor: #3FA2F7
  }
}
`;

const processingText = [
    "I'm creating a vector for your request...Each vector has a certain number of dimensions, which can range from tens to thousands, depending on the complexity and granularity of your request. It's all very nerdy... don't worry about it",
    "Now I'm reading all the documents in the index to find which ones will help me...",
    "I'm figuring out how to formulate a prompt that will get you the results you want",
    "Now I'm waiting... just like you :("
];

const strictModeText = " Do not embellish, base your answer strictly on the HITS given";
//It is important that you restrict yourself to using only the texts provided to formulate the answer, do not add anything other than the numbered text fragments.
const documentStrictText = " It is important that your answer combines the texts of the hits provided that best fit the question. Just return the exact text without adding anything"

export default function Chat({ sx }: Props) {
    const { user, setUserProfile, setUserLabel, updateChat, updateConversationStyle } = useUser();
    const { profileCloud } = useAdmin();
    const { labels } = useLabels();
    const { state } = useLocation();
    const { userId } = useApp();
    const isLgScreen = useMediaQuery(theme => theme.breakpoints.only("lg"));
    const isMdScreen = useMediaQuery(theme => theme.breakpoints.only("md"));
    const isSmScreen = useMediaQuery(theme => theme.breakpoints.only("sm"));
    const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const [collection, collectionLoading] = useCollection(`/profiles/${user?.profile}/documents`);
    const [input, setInput] = useState("");
    const [chat, setChat] = useState<UserChatMessage[]>([]);
    const [chatId, setChatId] = useState(new Date().toISOString());
    const [chatDate, setChatDate] = useState<Date>(new Date());
    const [loading, setLoading] = useState(false);
    const [preventLastChat, setPreventLastChat] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editText, setEditText] = useState<string | undefined>(undefined);
    const [editIndex, setEditIndex] = useState<UserChatMessage | undefined>(undefined);
    const [sendMessageFocus, setSendMessageFocus] = useState(false);
    const [strickMode, setStrickMode] = useState(false);
    const [exactTextMode, setExactTextMode] = useState(false);
    const [file, setFile] = useState<{ name: string, page: string }>({ name: "", page: "" });
    const [openPDF, setOpenPDF] = useState(false);
    const [, setBusy] = useState(false);
    const [showAssistant, setShowAssistant] = useState(false);
    // const [strictModeTooltip, setStrictModeTooltip] = useState(false);
    const [emptyDocsAlert, setEmptyDocsAlert] = useState(false);
    const [, setSelectedLabels] = useState<string[]>([]);
    const [processing, setProcessing] = useState(false);
    const [message, setMessage] = useState("");
    const [, setTyping] = useState(false);
    const [conversationStyle, setConversationStyle] = useState<ConversationStyleType | undefined>(user?.conversationStyle);
    const [temperature, setTemperature] = useState<number | undefined>(undefined);
    const [conversationStyleDialogOpen, setConversationStyleDialogOpen] = useState(false);
    const [showConversationStyle, setShowConversationStyle] = useState(true);
    const [model, setModel] = useState<35 | 40>(35);
    const [chatHistoryAlert, setChatHistoryAlert] = useState(false);

    useEffect(() => {
        if (!editIndex)
            updateScroll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message])

    useEffect(() => {
        if (user?.model)
            setModel(user.model);
    }, [user?.model])

    useEffect(() => {
        if (user?.conversationStyle)
            setConversationStyle(user?.conversationStyle);
    }, [user?.conversationStyle])

    useEffect(() => {
        if (message && message.length >= 1 && !message.includes("streamdone")) {
            setLoading(false);
            setProcessing(false);
            if (editIndex) {
                const index = chat.indexOf(editIndex);
                if (chat[index + 1].role === "assistant")
                    chat[index + 1].content = message;
            }
            else if (chat[chat.length - 1]?.role === "user") {
                chat.push({ role: "assistant", content: message });
            }
            else if (chat[chat.length - 1]?.role === "assistant") {
                chat[chat.length - 1].content = message;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message])

    useEffect(() => {
        if (state?.stateChatDate && state?.stateChatId) {
            setChatDate(state?.stateChatDate);
            const historyChat = user?.chats?.find(item => item.id === state.stateChatId);
            if (historyChat?.chat)
                setChat(historyChat.chat);
        }
        if (state?.resetChat && chat.length >= 1)
            setChat([]);
        setSendMessageFocus(state?.sendMessageFocus);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])

    useEffect(() => {
        const filteredChats = user?.chats?.filter(item => item.profile === user.profile);
        if (!state?.resetChat && !preventLastChat && (!chat || chat.length <= 0) && filteredChats && filteredChats.length >= 1) {
            const mostRecentChat = filteredChats?.reduce((prev, current) => (prev.date.toDate() > current.date.toDate()) ? prev : current);
            if (mostRecentChat && mostRecentChat.date.toDate().toDateString() === new Date().toDateString() && mostRecentChat.profile === user?.profile) {
                setChat(mostRecentChat.chat);
            }
        }
        // else if ((!filteredChats || filteredChats.length <= 0) && chat.length >= 2 && !loading)
        //     setChat([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.chats])

    async function handleSubmit(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>): Promise<void> {
        event.preventDefault();
        if (!collectionLoading && (!collection?.docs || collection?.docs?.length <= 0))
            setEmptyDocsAlert(true);
        if (!conversationStyle)
            setConversationStyle("balanced");
        if (user?.chats && user.chats.length >= 100)
            setChatHistoryAlert(true); 
        setLoading(true);
        setProcessing(true);
        setShowAssistant(false);
        setMessage("");
        setTyping(true);
        setShowConversationStyle(false);
        if (user?.profile) {
            const question = formatQuestion(editText || input);
            setPreventLastChat(false);
            setInput("");
            if (!chat) setChat([]);
            if (!chatDate) setChatDate(new Date());
            if (!chatId) setChatId(new Date().toISOString());
            if (editIndex && editText) {
                const index = chat.indexOf(editIndex);
                if (chat[index].content) {
                    chat[index].content = editText;
                    chat[index + 1].hits = undefined;
                }
            }
            else
                chat.push({ role: "user", content: question });
            const data = await ask({
                question,
                profile: user.profile,
                userId: userId!,
                chat,
                conversationStyle: conversationStyle || "balanced",
                setMessage,
                filter: user?.labels,
                tenant: user?.tenant,
                temperature,
                model
            });
            console.log(data);
            if (!data?.ok && data?.msg?.includes("This model's maximum context length")) {
                chat.push({
                    role: "assistant",
                    content: "This chat is about to hit the limit of messages. Please star a new chat by reseting this chat or click here"
                })
            }
            else if (data?.chat) {
                const chatData = data.chat.filter(message => message.role !== "system");
                setChat(chatData)
                const userChat = user?.chats?.filter(item => item.id !== chatId) || [];
                const sortedUserChat = userChat.sort((a, b) => b.date.toDate() - a.date.toDate());
                const finalChat = [{ chat: chatData, date: chatDate, id: chatId, profile: user.profile }, ...sortedUserChat];
                await updateChat(finalChat);
            }
            setLoading(false);
            setEditMode(false);
            setTyping(false);
            setMessage("");
            setEditText("");
            setEditIndex(undefined);
        }
    }

    function formatQuestion(question: string): string {
        if (!question.includes(strictModeText) && strickMode) {
            if (question.endsWith("?") || question.endsWith(".")) {
                question = question.concat(strictModeText);
            }
            else {
                question = question.concat(".").concat(strictModeText);
            }
        }
        return question;
    }

    function handleInputChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void {
        setInput(event.target.value);
    }

    function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>): void {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            handleSubmit(event);
        }
    }

    function handleReSubmitButton(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, question: UserChatMessage): void {
        event.preventDefault();
        setEditMode(false);
        handleSubmit(event);
    }

    function resetChat(): void {
        const chatLimitReset = chat.find(item => item.content.includes("This chat is about to hit the limit of messages"))
        if (chatLimitReset)
            setInput(chat[chat.length - 2].content)
        setChat([]);
        setPreventLastChat(true);
        setShowConversationStyle(true);
    }

    function updateScroll() {
        const element = document.getElementById("list");
        if (element) {
            element.scroll({ top: element.scrollHeight, left: 0, behavior: "auto" });
        }
    }

    function onChangeChat(event: React.ChangeEvent<HTMLInputElement>): void {
        setEditText(event.target.value);
    }

    function disableEdit(): void {
        setEditMode(!editMode);
        setEditText(undefined);
        setEditIndex(undefined);
    }

    function enableEdit(index: UserChatMessage): void {
        setEditMode(!editMode);
        setEditText(index.content);
        setEditIndex(index);
    }

    function onChangeCollection(collection: SelectChangeEvent<string>, setValue: React.Dispatch<React.SetStateAction<string | undefined>>): void {
        setUserProfile(collection.target.value);
        setValue(collection.target.value);
        setChat([]);
    }

    function onChangeLabel(event: SelectChangeEvent<string[]>): void {
        const { target: { value }, } = event;
        let values: string | string[];
        if (typeof event.target.value !== "string") {
            const preLabels = event.target.value.map(item => {
                return labels.find(label => label.name === item || label.id === item)?.id
            })
            const fixedPreLabels = preLabels as string[];
            const removeAllDupes = fixedPreLabels.filter((element, index) => {
                return fixedPreLabels.indexOf(element) !== index;
            });
            const finalPreLabel = fixedPreLabels.filter(item => item !== removeAllDupes[0]);
            values = finalPreLabel;
        }
        else {
            values = labels.find(item => item.name === value)!.id;
        }
        console.log(values);
        setSelectedLabels(
            typeof values === 'string' ? values.split(',') : values,
        );
        setUserLabel(
            typeof values === 'string' ? values.split(',') : values,
        );
    }

    function handlePDFClose(): void {
        setOpenPDF(false);
    }

    async function handleOpen(file: { name: string, page: string }) {
        setBusy(true);
        if (file && "responses" && user?.profile) {
            try {
                const link = await getPDFLink({ profile: user.profile, folder: "responses", file: file.name });
                setFile({ name: link, page: file.page });
                setOpenPDF(true)
                if (file.name.includes(".xlsx"))
                    window.open(link, "_blank")
            }
            catch (err) {
                console.error(err);
            }
        }
        setBusy(false);
    }

    function calculateHeight(): string {
        if (isLgScreen)
            return showConversationStyle ? "60vh" : "64vh";
        else if (isSmScreen)
            return showConversationStyle ? "57vh" : "61vh";
        else if (isMdScreen)
            return showConversationStyle ? "63vh" : "67vh";
        else if (isMobile)
            return showConversationStyle ? "49vh" : "55vh";
        else
            return showConversationStyle ? "62vh" : "69vh";
    }

    function calculateMarginLeft(): number {
        if (!isMobile)
            return 6;
        else
            return 0;
    }

    // function handleStrictModeTooltip(): void {
    //     setStrictModeTooltip(false);
    // }

    function processingDialogClose(): void {
        setProcessing(false);
    }

    async function onClickConversationStyle(style: ConversationStyleType): Promise<void> {
        setConversationStyle(style);
        if (style === "creative") {
            setTemperature(profileCloud?.ask?.conversationProfiles?.creative?.temperature);
            setExactTextMode(profileCloud?.ask?.conversationProfiles?.creative?.exactTextMode || false);
            setStrickMode(profileCloud?.ask?.conversationProfiles?.creative?.strictMode || false);
        }
        if (style === "balanced") {
            setTemperature(profileCloud?.ask?.conversationProfiles?.balanced?.temperature);
            setExactTextMode(profileCloud?.ask?.conversationProfiles?.balanced?.exactTextMode || false);
            setStrickMode(profileCloud?.ask?.conversationProfiles?.balanced?.strictMode || false);
        }
        if (style === "precise") {
            setTemperature(profileCloud?.ask?.conversationProfiles?.precise?.temperature);
            setExactTextMode(profileCloud?.ask?.conversationProfiles?.precise?.exactTextMode || false);
            setStrickMode(profileCloud?.ask?.conversationProfiles?.precise?.strictMode || false);
        }
        await updateConversationStyle(style);
    }

    function chatAction(messages: UserChatMessage): JSX.Element | undefined {
        if (messages.role === "user" && !editMode && !isMobile) {
            return (
                <Stack justifyContent="flex-end" alignItems="flex-end">
                    <IconButton
                        disableRipple
                        onClick={() => enableEdit(messages)}
                        edge="end"
                    >
                        <EditNoteIcon sx={{ color: "#C3C3C3" }} fontSize="small" />
                    </IconButton>
                </Stack>
            );
        }
        if (messages.role === "assistant" && !isMobile) {
            return (
                <Stack justifyContent="flex-end" alignItems="flex-end">
                    <IconButton
                        disableRipple
                        onClick={() => { navigator.clipboard.writeText(messages.content) }}
                        edge="end"
                    >
                        <CopyIcon sx={{ color: "#C3C3C3" }} fontSize="small" />
                    </IconButton>
                </Stack>

            );
        }
    }

    function parseMessage(chat: UserChatMessage): JSX.Element {
        chat.content = chat.content.replace(strictModeText, "").replace(documentStrictText, "").replace("null", "").replace("SUMMARY:", "");
        const parsed = chat.content;
        return (
            <>
                {(!editMode || chat.role === "assistant" || chat !== editIndex) && (
                    <>
                        {chatAction(chat)}
                        <Markdown remarkPlugins={[remarkGfm]}>
                            {parsed}
                        </Markdown>
                        {chat.role === "user" && !editMode && isMobile && (
                            <IconButton disableRipple onClick={() => enableEdit(chat)} edge="end">
                                <EditNoteIcon sx={{ color: "#C3C3C3" }} fontSize="small" />
                            </IconButton>
                        )}

                        {chat.role === "assistant" && isMobile && (
                            <IconButton
                                disableRipple
                                onClick={() => { navigator.clipboard.writeText(chat.content) }}
                                edge="end"
                            >
                                <CopyIcon sx={{ color: "#C3C3C3" }} fontSize="small" />
                            </IconButton>
                        )}
                    </>
                )}
                {editMode && chat.role === "user" && chat === editIndex && (
                    <>
                        <Stack spacing={2} direction="row">
                            <TextField
                                margin="normal"
                                fullWidth
                                multiline
                                variant="standard"
                                value={editText}
                                id="question"
                                name="question"
                                onChange={onChangeChat}
                                autoFocus
                                InputProps={{ disableUnderline: true }}
                            />
                        </Stack>
                        <br />
                        <Stack spacing={2} direction="row" justifyContent="center">
                            <StyledLoadingButton
                                id="resubmitt"
                                type="button"
                                size="medium"
                                variant="contained"
                                onClick={(event) => handleReSubmitButton(event, chat)}
                                loading={loading}
                                sx={{ alignItems: "flex-start", bgcolor: "#3FA2F7", borderRadius: 7, whiteSpace: "nowrap" }}
                            >
                                Save & Submit
                            </StyledLoadingButton>
                            <StyledButton
                                id="cancelbutton"
                                type="button"
                                size="medium"
                                variant="outlined"
                                onClick={disableEdit}
                                sx={{ borderRadius: 7, color: "#3FA2F7" }}
                            >
                                Cancel
                            </StyledButton>
                        </Stack>
                    </>
                )}
                {chat.role === "assistant" && chat.hits && chat.hits.length >= 1 && (
                    <List>
                        <Collapsible
                            variant="default"
                            title="Citations"
                        >
                            {chat.hits.map(reference => (
                                <>
                                    <br />
                                    <Typography fontFamily="Roboto"><b>{reference.heading}</b></Typography>
                                    <Link href="#" onClick={() => handleOpen({ name: reference.file, page: reference.page })}>
                                        <Typography sx={{ color: "black" }} fontFamily="Roboto">{reference.file} (page&nbsp;{reference.page})</Typography>
                                    </Link>
                                    <Typography fontFamily="Roboto" sx={{ textAlign: isMobile ? "justify" : "left" }}><b>Score: {reference.textScore}%</b></Typography>
                                    <Typography fontFamily="Roboto" sx={{ textAlign: isMobile ? "justify" : "left" }}>{reference.summary}</Typography>
                                    <br />
                                    <Typography fontFamily="Roboto" sx={{ textAlign: isMobile ? "justify" : "left" }}>{reference.hitText || reference.summary}</Typography>
                                    <br />
                                </>
                            ))}
                        </Collapsible>
                    </List>
                )}
            </>
        );
    };

    return (
        <Box>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
                    sx={
                        chat.length >= 1 ? { overflowY: "auto", height: calculateHeight(), maxHeight: "75vh" }
                            : { overflowY: "auto", maxHeight: "70vh" }
                    }
                >
                    <AlertPopUp
                        open={!!emptyDocsAlert}
                        autoHideDuration={7500}
                        handleClose={() => setEmptyDocsAlert(false)}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        severity="info"
                        title="Empty Collection"
                        msg={(<Typography>The {user?.profile} collection doesn't have documents. Please go to the <Link href="/documents/responses/">index</Link> page and start uploading your documents.</Typography>)}
                    />

                    <AlertPopUp
                        open={chatHistoryAlert}
                        autoHideDuration={8000}
                        handleClose={() => setChatHistoryAlert(false)}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        severity="info"
                        title="Chat History Issue"
                        msg={(<Typography variant="button">Your chat history has +100 conversations stored.<br />To continue using the chat without issues, we recommend you to purge your history.</Typography>)}
                    />

                    <List
                        id="list"
                        sx={
                            chat.length >= 1 ? { overflowY: "auto", height: calculateHeight(), maxHeight: "75vh" }
                                : { overflowY: "auto", maxHeight: "70vh" }
                        }
                    >

                        <TransitionDialog
                            open={processing}
                            text={processingText}
                            processingStep={user?.chatProcessing || 0}
                            handleClose={processingDialogClose}
                        />

                        {chat && chat.length >= 1 && chat.map(messages => (
                            <ListItem
                                sx={{ bgcolor: "white", lineHeight: 3, pl: 0.2 }}
                                alignItems="flex-start"
                            >
                                {file.name.toLowerCase().includes(".pdf") && <PDFViewer page={file.page} open={openPDF} file={file.name} handleClose={handlePDFClose} />}

                                <ListItemAvatar>
                                    {messages.role === "assistant" && (<img src={logo} width={35} height={28} alt="" />)}
                                    {messages.role === "user" && (<PersonIcon fontSize="medium" sx={{ color: "#3FA2F7", ml: 0.8 }} />)}
                                </ListItemAvatar>

                                <Card
                                    variant="outlined"
                                    sx={{ borderRadius: 4, width: "100%" }}
                                >

                                    <CardContent
                                        sx={{
                                            textAlign: "justify",
                                            marginRight: !isMobile ? 2 : 0,
                                            fontSize: "16px",
                                            color: "#000000",
                                            lineHeight: !isMobile ? 2 : 0,
                                            wordWrap: "break-word",
                                            whiteSpace: "pre-wrap"
                                        }}
                                    >
                                        {parseMessage(messages)}
                                    </CardContent>
                                </Card>
                            </ListItem>
                        ))}
                        <br />
                    </List>
                </Grid>
                {showConversationStyle && (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ConversationStyle
                            conversationStyle={conversationStyle}
                            onClick={onClickConversationStyle}
                        />
                    </Grid>
                )}
                <Grid item xs={12} sm={10.26} md={11} lg={11.12} xl={11.02} sx={{ ml: calculateMarginLeft() }}>
                    <ChatInput
                        placeholder="Ask IKA to do something..."
                        variant="outlined"
                        fullWidth
                        value={input}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        autoFocus={sendMessageFocus}
                        multiline
                        focused
                        InputProps={{
                            sx: {
                                bgcolor: "white",
                                borderRadius: "15px",
                                mt: !isMobile ? 2 : 0,
                                ml: !isMobile ? 1 : 0,
                            },
                            endAdornment: (
                                <InputAdornment position="end">
                                    {chat && chat.length >= 1 && (
                                        <Tooltip title="Restart Chat">
                                            <IconButton sx={{ marginRight: 0.5 }} onClick={resetChat} edge="end">
                                                <RestartIcon sx={{ color: "#3FA2F7" }} fontSize="medium" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    <IconButton onClick={handleSubmit} edge="end">
                                        <SendIcon sx={{ color: "#3FA2F7" }} fontSize="medium" />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={3} md={6} lg={4} xl={3} sx={{ ml: !isMobile ? 7 : 1 }}>
                    <IconButton disableRipple onClick={() => setConversationStyleDialogOpen(true)}>
                        <SettingsIcon sx={{ color: "#3FA2F7" }} fontSize="small" />
                        <Typography sx={{ ml: 0.5, color: "#3FA2F7" }} variant="button">Chat Settings</Typography>
                    </IconButton>
                    <IconButton disableRipple onClick={() => setShowConversationStyle(!showConversationStyle)}>
                        <ConversationStyleIcon sx={{ color: "#3FA2F7" }} fontSize="small" />
                        <Typography sx={{ ml: 0.5, color: "#3FA2F7" }} variant="button">Conversation Style</Typography>
                    </IconButton>
                    <ConversationStyleDialog
                        open={conversationStyleDialogOpen}
                        model={model}
                        setModel={setModel}
                        setOpen={setConversationStyleDialogOpen}
                    />
                </Grid>
                <Grid item xs={12} sm={7.23} md={5} lg={7.1} xl={8}>
                    <Stack direction="row" justifyContent="right" spacing={0.5}>
                        {!isMobile && (
                            <StyledButton variant="outlined" onClick={() => setShowAssistant(true)}>
                                <Typography variant="button">Assistant</Typography>
                                <ArrowDownIcon />
                            </StyledButton>
                        )}
                        {isMobile && (
                            <IconButton onClick={() => setShowAssistant(true)}>
                                <AssistantIcon fontSize="medium" sx={{ color: "#3FA2F7" }} />
                            </IconButton>
                        )}
                        <PromptAssistantDialog
                            handleSubmit={handleSubmit}
                            chatPrompt={input}
                            open={showAssistant}
                            setChatPrompt={setInput}
                            setOpen={setShowAssistant}
                            onClose={() => setShowAssistant(false)}
                        />
                        {labels && labels.length >= 1 && (
                            <LabelSelector
                                selectedLabels={user?.labels || []}
                                labels={labels}
                                onChange={onChangeLabel}
                            />
                        )}
                        <CollectionSelector
                            userProfiles={user?.profiles}
                            profile={user?.profile}
                            onChange={onChangeCollection}
                        />
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
}
