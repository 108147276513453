import * as React from 'react';

import {
    Dialog,
    DialogContent,
    DialogTitle,
    Slide,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Typography
} from "@mui/material";

import { TransitionProps } from '@mui/material/transitions';

export interface TransitionDialogProps {
    open: boolean;
    text: string[];
    processingStep: number;
    handleClose: () => void;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function TransitionDialog({ open, text, processingStep, handleClose }: TransitionDialogProps) {
    const steps = [
        {
            label: "Searching for documents",
            description: "I'm creating a vector for your request...Each vector has a certain number of dimensions, which can range from tens to thousands, depending on the complexity and granularity of your request. It's all very nerdy... don't worry about it",
        },
        {
            label: "Reading and selecting documents",
            description: "Now I'm reading all the documents in the index to find which ones will help me...",
        },
        {
            label: "Crafting the prompt",
            description: "I'm figuring out how to formulate a prompt that will get you the results you want",
        },
        {
            label: "Sending it to AI",
            description: "Now I'm waiting... just like you :(",
        }
    ];

    return (
        <React.Fragment>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                maxWidth="sm"
            >
                <DialogTitle variant="h5" color="text.secondary">Processing Request</DialogTitle>
                <DialogContent >
                    <Stepper activeStep={processingStep} orientation="vertical">
                        {steps.map(step => (
                            <Step key={step.label}>
                                <StepLabel>
                                    <Typography variant="h6">{step.label}</Typography>
                                </StepLabel>
                                <StepContent>
                                    <Typography>{step.description}</Typography>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}