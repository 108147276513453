import React, { useState } from "react";
import { Tooltip } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useUser, useDocumentPath } from "./context";
import { getPDFLink } from "./lib";

import { 
    PictureAsPdf as PDFIcon,
    DownloadForOffline as ExcelIcon
} from "@mui/icons-material"


export interface Props {
    disabled?: boolean;
    file?: string;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setFile: React.Dispatch<React.SetStateAction<string>>;
}

export default ({ disabled, file, setFile, setOpen }: Props) => {
    const { user } = useUser();
    const { folder } = useDocumentPath();
    const [busy, setBusy] = useState(false);

    async function handleOpen() {
        setBusy(true);
        if (file && (folder || "responses") && user?.profile) {
            try {
                const link = await getPDFLink({ profile: user.profile, folder: folder || "responses", file });
                setFile(link);
                setOpen(true)
                if(file.includes(".xlsx"))
                    window.open(link, "_blank")
            }
            catch (err) {
                console.error(err);
            }
        }
        setBusy(false);
    }

    return (
        <Tooltip title={file?.toLowerCase()?.includes("pdf") ? "Open this file" : "Download this file"}>
            <LoadingButton
                variant="text"
                loading={busy}
                disabled={disabled}
                onClick={handleOpen}
            >
                { file?.toLowerCase()?.includes(".pdf") && <PDFIcon fontSize="small" /> }
                { file?.toLowerCase()?.includes(".xlsx") && <ExcelIcon fontSize="small" /> }
            </LoadingButton>
        </Tooltip>
    );
};
