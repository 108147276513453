import React, { useState } from "react";

import {
    Alert,
    AlertTitle,
    Box,
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography,
    TextField
} from "@mui/material";

import {
    Google as GoogleIcon,
    Mail as MailIcon,
    Microsoft as MicrosoftIcon
} from "@mui/icons-material";

import { useUser } from "../context";
import { createTenant } from "../lib";
import { StyledButton, StyledLoadingButton } from "../components/StyledComponents"

export interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onClose: (event: React.SyntheticEvent) => void;
}

export type AddUserTab = "google" | "microsoft" | "password";

export default function ManageTenants({ open, setOpen, onClose }: Props) {
    const { user } = useUser();
    const [busy, setBusy] = useState(false);
    const [tenant, setTenant] = useState("");
    const [error, setError] = useState<string | undefined>(undefined);
    const [googleBgColor, setGoogleBgColor] = useState("white");
    const [microsoftBgColor, setMicrosoftBgColor] = useState("white");
    const [passwordBgColor, setPasswordBgColor] = useState("white");
    const [providers, setProviders] = useState<AddUserTab[]>([]);
    console.log(JSON.stringify(providers));
    function onChangeTenant(event: React.ChangeEvent<HTMLInputElement>): void {
        setTenant(event.target.value);
    }

    function handleProviders(provider: AddUserTab, color: string) {
        if (providers.includes(provider))
            setProviders(providers.filter(item => item !== provider));
        else
            providers.push(provider);
        if (provider === "google")
            setGoogleBgColor(color)
        if (provider === "microsoft")
            setMicrosoftBgColor(color)
        if (provider === "password")
            setPasswordBgColor(color)
    }

    function handleClose() {
        setGoogleBgColor("white");
        setMicrosoftBgColor("white");
        setPasswordBgColor("white");
        setTenant("");
        setProviders([]);
        setOpen(false);
    }

    async function handleSubmit(event: React.ChangeEvent<HTMLFormElement>): Promise<any> {
        try {
            if (user?.profile) {
                event.preventDefault();
                setError(undefined);
                setBusy(true);
                await createTenant(user.profile, tenant, providers)
                setBusy(false);
                handleClose();
            }
        }
        catch (err) {
            setError(err instanceof Error ? err.message : JSON.stringify(err))
            setBusy(false);
        }
    };

    return (
        <Box>
            {open && (
                <>
                    <Dialog open={open} onClose={() => setOpen(false)}>
                        <DialogTitle>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Typography variant="h6">Create Tenant</Typography>
                            </Stack>
                        </DialogTitle>
                        <DialogContent>
                            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="tenant"
                                    label="Tenant name"
                                    name="tenant"
                                    value={tenant}
                                    onChange={onChangeTenant}
                                    autoFocus
                                />

                                <Typography>Sign up providers</Typography>
                                <ButtonGroup aria-label="medium secondary button group">
                                    <IconButton
                                        sx={{ bgcolor: googleBgColor }}
                                        onClick={() => googleBgColor === "white" ? handleProviders("google", "#3FA2F7") : handleProviders("google", "white")} key="google"
                                    >
                                        <GoogleIcon />
                                    </IconButton>
                                    <IconButton
                                        sx={{ bgcolor: microsoftBgColor }}
                                        onClick={() => microsoftBgColor === "white" ? handleProviders("microsoft", "#3FA2F7") : handleProviders("microsoft", "white")} key="microsoft"
                                    >
                                        <MicrosoftIcon />
                                    </IconButton>
                                    <IconButton
                                        sx={{ bgcolor: passwordBgColor }}
                                        onClick={() => passwordBgColor === "white" ? handleProviders("password", "#3FA2F7") : handleProviders("password", "white")} key="password"
                                    >
                                        <MailIcon />
                                    </IconButton>
                                </ButtonGroup>


                                <StyledLoadingButton
                                    id="email"
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    loading={busy}
                                    disabled={!tenant}
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Submit
                                </StyledLoadingButton>
                                {error && <Alert severity="error">
                                    <AlertTitle>Error</AlertTitle>
                                    {error}
                                </Alert>}
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <StyledButton onClick={handleClose}>Cancel</StyledButton>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </Box>
    );
}
