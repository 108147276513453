import { Button, ButtonProps, Select, SelectProps, TextField, styled } from "@mui/material"
import { LoadingButton, LoadingButtonProps } from "@mui/lab";

interface MuiLoadingButtonProps extends LoadingButtonProps {
    component?: string;
}

interface MuiSelectProps extends SelectProps {
    breakpoint: "xs" | "sm" | "md" | "lg" | "xl" | undefined;
    smaller: boolean;
}

export const StyledButton = styled(Button)<ButtonProps>(({ theme, variant, color }) => ({
    borderRadius: "20px",
    ...(variant !== "contained" && {
        border:"1px solid silver",
    }),
    bgcolor: "white",
    color: "#3FA2F7",
    disableElevation: true,
    ...(color !== "secondary" && {
        "&:hover": {
            backgroundColor: "#3FA2F7",
            color: "white"
        }
    }),
    ...(variant === "contained" && {
        bgcolor: "#3FA2F7",
        color: "white",
    })
}))

export const StyledLoadingButton = styled(LoadingButton)<MuiLoadingButtonProps>(({ theme, variant }) => ({
    borderRadius: "20px",
    ...(variant !== "contained" && {
        border:"1px solid silver",
    }),
    bgcolor: "white",
    color: "#3FA2F7",
    "&:hover": {
        backgroundColor: "#3FA2F7",
        color: "white"
    },
    ...(variant === "contained" && {
        bgcolor: "#3FA2F7",
        color: "white"
    })
}));

export const StyledSelect = styled(Select)<MuiSelectProps>(({ theme }) => ({
    minWidth: 200,
    color: "#3FA2F7",
    bgcolor: "white",
    borderRadius: 6,
    "& .MuiSelect-icon": { color: "#3FA2F7" }
}))

export const MuiTextField = styled(TextField)(({theme}) => ({
    borderRadius: "15px"
}))