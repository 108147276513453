import React, { useState } from "react";

import {
    Box,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Stack,
    Typography,
    TextField
} from "@mui/material";


import {
    Delete as DeleteIcon,
    Edit as EditNoteIcon,
    CheckCircle as CheckIcon
} from "@mui/icons-material"

import { StyledButton } from "../components";
import { useProfile, useUser } from "../context";

export interface Props {
    characters?: string[];
    characterInputText?: string;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function CharacterDialog({ characters, open, characterInputText, setOpen }: Props) {
    const { user } = useUser();
    const { addPromptAssistant, deletePromptAssistant, updatePromptAssistant, updateText } = useProfile();
    const [editText, setEditText] = useState<string | undefined>(undefined);
    const [editingChip, setEditingChip] = useState<string | undefined>(undefined);
    const [addingCharacter, setAddingCharacter] = useState(false);
    const type = "characters";
    const textType = "characterText";

    async function onDelete(character: string) {
        if (user?.profile)
            await deletePromptAssistant(character, type, user.profile);
    }

    function onChangeChip(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        setEditText(event.target.value);
    }

    async function onChangeInputText(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        if (user?.profile)
            await updateText(event.target.value, textType, user.profile)
    }

    function onSelectEditChip(character: string | undefined) {
        setEditingChip(character);
        setEditText(character);
    }

    async function submitCharacterEdit(character: string) {
        if (editText && user?.profile) {
            await updatePromptAssistant(character, editText, type, user?.profile);
            setEditingChip(undefined);
            setEditText(undefined);
        }
    }

    async function addNewCharacter() {
        if (editText && user?.profile) {
            await addPromptAssistant(editText, type, user?.profile);
            setEditText(undefined);
            setAddingCharacter(false);
        }
    }

    function onClose() {
        setAddingCharacter(false);
        setEditText(undefined);
        setEditingChip(undefined);
        setOpen(false);
    }

    return (
        <Box>
            {open && (
                <>
                    <Dialog scroll="paper" maxWidth="sm" fullWidth open={open} onClose={() => setOpen(false)}>
                        <DialogTitle>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Typography variant="h6">CHARACTERS</Typography>
                            </Stack>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Characters are the roles in your firm that you want IKA to immitate
                                when authoring content. Some examples are "CMO", "Proposal
                                Author", "Lawyer with deep experience in Intellectual Property"
                            </DialogContentText>
                        </DialogContent>
                        <DialogContent>
                            <DialogContentText>
                                <Typography variant="h6">INPUT TEXT</Typography>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    value={characterInputText}
                                    id="inputText"
                                    name="inputText"
                                    onChange={onChangeInputText}
                                />
                            </DialogContentText>
                        </DialogContent>
                        <DialogContent>
                            <Grid container direction="column" sx={{ mt: 1 }}>
                                <Grid item md={12} lg={12}>
                                    {!addingCharacter && (
                                        <Stack spacing={1.5}>
                                            {characters?.map(character => (
                                                <Stack direction="row" spacing={1}>
                                                    {editingChip !== character && (
                                                        <Chip
                                                            sx={{ borderRadius: 1, minWidth: 500, maxWidth: 500, whiteSpace: "normal", justifyContent: "left", bgcolor: "#EBF1F9", position: "relative" }}
                                                            label={character}
                                                            size="medium"
                                                            deleteIcon={<DeleteIcon sx={{ position: "absolute", right: 0 }} />}
                                                            onDelete={() => onDelete(character)}
                                                        />
                                                    )}
                                                    {editingChip === character && (
                                                        <TextField
                                                            margin="normal"
                                                            variant="standard"
                                                            multiline
                                                            value={editText}
                                                            id="chipEdit"
                                                            name="Characater"
                                                            sx={{ minWidth: 400 }}
                                                            onChange={onChangeChip}
                                                            autoFocus
                                                        />
                                                    )}
                                                    {editingChip === character && (
                                                        <IconButton disableRipple onClick={() => submitCharacterEdit(character)} edge="end">
                                                            <CheckIcon sx={{ color: "#3FA2F7", mt: 2, ml: 1 }} fontSize="medium" />
                                                        </IconButton>
                                                    )}
                                                    {editingChip !== character && (
                                                        <IconButton disableRipple onClick={() => onSelectEditChip(character)} edge="end">
                                                            <EditNoteIcon sx={{ color: "#C3C3C3", mb: 1 }} fontSize="small" />
                                                        </IconButton>
                                                    )}
                                                    <br />
                                                    <br />
                                                </Stack>
                                            ))
                                            }
                                        </Stack>
                                    )}
                                    {addingCharacter && (
                                        <>
                                            <Typography variant="h6">ADD CHARACTER</Typography>
                                            <TextField
                                                margin="normal"
                                                multiline
                                                value={editText}
                                                id="chipEdit"
                                                name="Characater"
                                                sx={{ minWidth: 400 }}
                                                onChange={onChangeChip}
                                                autoFocus
                                            />
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: "left" }}>
                            {!addingCharacter && (
                                <StyledButton
                                    sx={{ mb: 1, ml: 2, borderRadius: 5, bgcolor: "#3FA2F7", color: "white", minWidth: 130 }}
                                    variant="contained"
                                    onClick={() => setAddingCharacter(true)}
                                >
                                    Add option
                                </StyledButton>
                            )}
                            {addingCharacter && (
                                <StyledButton
                                    onClick={addNewCharacter}
                                    sx={{ mb: 1, ml: 2, borderRadius: 5, bgcolor: "#3FA2F7", color: "white", minWidth: 130 }}
                                    variant="contained"
                                    >
                                    Save
                                </StyledButton>
                            )}
                            <StyledButton
                                sx={{ mb: 1, ml: 2, borderRadius: 5, bgcolor: "white", color: "#3FA2F7", minWidth: 130 }}
                                variant="contained"
                                onClick={onClose}
                            >
                                Close
                            </StyledButton>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </Box>
    );
}
