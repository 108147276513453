import logo from "../assets/images/ikaun-logo-small-200.png";

import {
    Box,
    Stack,
    Typography
} from "@mui/material";
import AlertPopUp from "../components/AlertPopUp";

export interface FormStructureProps {
    children: React.ReactNode;
    error: string | undefined;
    handleCloseAlert: () => void;
}

export default function FormStructure({ children, error, handleCloseAlert }: FormStructureProps) {
    return (
        <Stack alignItems="center" component="main">
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                boxShadow="3"
                borderRadius="2"
                sx={{ px: 4, py: 6, mt: 1 }}
            >
                <img src={logo} style={{ marginBottom: 10 }}  width={64} alt="" />
                <br />

                <AlertPopUp 
                    open={!!error}
                    autoHideDuration={6000}
                    handleClose={handleCloseAlert}
                    anchorOrigin={{ vertical: "top",  horizontal: "right" }}
                    severity="error"
                    title="Error"
                    msg={error}
                />

                <Typography noWrap component="h1" variant="h5" color="text.secondary">
                    Login to Your Account
                </Typography>
                <br />

                {children}

            </Box>
        </Stack>
    );
}