import React, { useState } from "react";
import { useProfile, useUser } from "../context";

import {
    IconButton,
    LinearProgress,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography
} from "@mui/material";

import {
    Edit as EditNoteIcon,
    KeyboardArrowDown as ArrowDownIcon
} from "@mui/icons-material"

import CharacterDialog from "./CharacterDialog";
import { getMinWidth } from "./PromptAssistant";

export interface Props {
    value?: string;
    breakpoint: "xs" | "sm" | "md" | "lg" | "xl" | undefined;
    onChange: (collection: SelectChangeEvent<string>) => void;
}

export default function CharacterSelector({ value, breakpoint, onChange }: Props) {
    const { user } = useUser();
    const { getProfile } = useProfile();
    const profile = getProfile(user?.profile);
    const characters = profile?.promptAssistant?.characters;
    const inputText = profile?.promptAssistant?.characterText;
    const [showEditDialog, setShowEditDialog] = useState(false);

    if (user)
        return (
            <>
                <Select
                    value={value}
                    size="small"
                    onChange={(event) => onChange(event)}
                    IconComponent={ArrowDownIcon}
                    disabled={!characters || characters?.length <= 0}
                    autoWidth
                    renderValue={(character: string | undefined) => {
                        if (value)
                            return (
                                <Typography variant="body1" sx={{ color: "#3FA2F7", whiteSpace: "normal" }}><b>{character}</b></Typography>
                            );
                    }}
                    sx={{ minWidth: getMinWidth(breakpoint, true), maxWidth: 650, color: "#3FA2F7", bgcolor: "white", borderRadius: 6, "& .MuiSelect-icon": { color: "#3FA2F7" } }}
                >
                    {characters?.map(character => (
                        <MenuItem
                            value={character}
                            selected={value === character}
                            sx={{ maxWidth: 650, whiteSpace: "normal" }}
                        >
                            <Typography variant="body1" sx={{ color: "#3FA2F7", textOverflow: "ellipsis" }}><b>{character}</b></Typography>
                        </MenuItem>
                    ))}
                </Select>
                <IconButton disableRipple onClick={() => setShowEditDialog(true)} edge="end">
                    <EditNoteIcon sx={{ color: "#C3C3C3", mb: 1 }} fontSize="small" />
                </IconButton>
                <CharacterDialog characters={characters} open={showEditDialog} characterInputText={inputText} setOpen={setShowEditDialog} />
            </>
        );
    else
        return (
            <LinearProgress sx={{ mt: 2 }} />
        );
}