import React, { useState } from "react";
import AppBar from "./AppBar";
import ChatHistory, { drawerWidth } from "./ChatHistory";

import {
    Container,
    CssBaseline,
    Grid,
    Paper,
    PaperProps as MuiPaperProps,
    styled,
    Breakpoint
} from "@mui/material";
import { useApp } from "./AppContext";
import { useBreakpoint } from "./context";

export interface Props {
    children: React.ReactNode;
}

export interface PaperProps extends MuiPaperProps {
    open?: boolean;
    breakpoint?: Breakpoint;
}

const PaperNew = styled(Paper, {
    shouldForwardProp: (prop) => prop !== 'open',
})<PaperProps>(({ theme, open, breakpoint }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        //width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: breakpoint !== "sm" ? "180px" : "167px",
        width: breakpoint !== "sm" ? "92%" : "85%",
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export default function AppFrame({ children }: Props) {
    const { authenticated } = useApp();
    const { breakpoint } = useBreakpoint();
    const isMobile = breakpoint === "xs";
    const [openDrawer, setOpenDrawer] = useState(!isMobile && authenticated ? true : false);

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    };

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    };

    return (
        <Grid container direction="row">
            <Grid
                item
                xs={12} sm={12} md={12} lg={12} xl={12}
                sx={{ bgcolor: "white" }}
            >
                <CssBaseline />
                <AppBar
                    drawerOpen={openDrawer}
                    handleDrawerOpen={handleDrawerOpen}
                    handleDrawerClose={handleDrawerClose}
                />
                <ChatHistory
                    open={openDrawer}
                    handleDrawerOpen={handleDrawerOpen}
                    handleDrawerClose={handleDrawerClose}
                />
                {isMobile && (
                    <PaperNew
                        elevation={0}
                        sx={{
                            width: "100%",
                            maxHeight: "100%",
                            height: "100%",
                            overflowY: "auto",
                            mt: 1,
                            bgcolor: "white",
                            paddingLeft: 1,
                            paddingRight: 1
                        }}
                        open={openDrawer}
                    >
                        {children}
                    </PaperNew>
                )}
                {breakpoint === "sm" && (
                    <PaperNew
                        elevation={0}
                        sx={{
                            maxHeight: "100%",
                            height: "100%",
                            overflowY: "auto",
                            mt: 1,
                            bgcolor: "white",
                            p: 6
                        }}
                        open={openDrawer}
                        breakpoint={breakpoint}
                    >
                        {children}
                    </PaperNew>
                )}
                {(!isMobile && breakpoint !== "sm") && (
                    <Container maxWidth={breakpoint}>
                        <PaperNew
                            elevation={0}
                            sx={{
                                maxHeight: "100%",
                                overflowY: "auto",
                                mt: 1,
                                p: 6,
                                bgcolor: "white"
                            }}
                            open={openDrawer}
                        >
                            {children}
                        </PaperNew>
                    </Container>
                )}
            </Grid>
        </Grid>
    );
};
