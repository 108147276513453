import React from "react";
import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
            main: "#3FA2F7", // dark blue
            //light: "#52affe", // light blue
            //dark: "#3b7cc5" // medium blue
        },
        secondary: {
            main: "#f91c3d", // red/orange
            light: "#fd94c9", // pink
            dark: "#f91c3d"
        },
        background: {
            default: "#fff" // white background color
        },
        text: {
            primary: "#253142", // dark text color #595f6f #253142
            secondary: "#1C1E2A" // dark blue #004389 #1C1E2A
        },
        info: {
            main: "#3FA2F7",
            light: "#4fc3f7",
            dark: "#0288d1"
        },
        success: {
            main: "#66bb6a",
            light: "#81c784",
            dark: "#388e3c"
        },
        error: {
            main: "#f44336",
            light: "#e57373",
            dark: "#d32f2f"
        },
        warning: {
            main: "#ffa726",
            light: "#ffb74d",
            dark: "#f57c00"
        }
    },
    typography: {
        fontFamily: "Söhne, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, Helvetica Neue, Arial, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji", // default font family used in the website
        h1: {
            fontWeight: 600,
            fontSize: "36px",
        },
        h2: {
            fontWeight: 600,
            fontSize: "24px",
        },
        h3: {
            fontWeight: 600,
            fontSize: "20px",
        },
        h4: {
            fontWeight: 700,
        },
        h5: {
            fontWeight: 700,
        },
        h6: {
            fontWeight: 700,
        },
        body1: {
            font: "Arial",
            fontSize: "16",
            fontWeight: "normal",
        },
        body2: { 
            font: "Roboto",
            fontSize: "14px",
            fontWeight: "normal",
        },
        button: {
            textTransform: "none", // Remove the default uppercase transformation on buttons,
            fontWeight: "bold"
        }
    }
});
