import React, { useEffect, useState } from "react";

import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    SelectChangeEvent,
    Stack,
    TextField,
    Typography,
} from "@mui/material";

import { StyledButton, StyledLoadingButton } from "../components";
import { useBreakpoint, useProfile, useUser } from "../context";

import CharacterSelector from "./CharacterSelector";
import RequestSelector from "./RequestsSelector";
import OutputSelector from "./OutputSelector";
import PracticesSelector from "./PracticesSelector";
import IndustriesSelector from "./IndustriesSelector";


export interface Props {
    open: boolean;
    chatPrompt: string;
    handleSubmit(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>): Promise<void>;
    setChatPrompt: React.Dispatch<React.SetStateAction<string>>;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onClose: (event: React.SyntheticEvent) => void;
}

export function getMinWidth(breakpoint: "xs" | "sm" | "md" | "lg" | "xl" | undefined, characterSelector: boolean): string | number {
    if (breakpoint === "xs")
        return 250
    else if (characterSelector)
        return 200
    else
        return 650;
}

export type AddUserTab = "google" | "microsoft" | "password";

export default function PromptAssistantDialog({ chatPrompt, open, handleSubmit, setChatPrompt, setOpen, onClose }: Props) {
    const { user } = useUser();
    const [busy] = useState(false);
    const { breakpoint } = useBreakpoint();
    const { getProfile } = useProfile();
    const [character, setCharacter] = useState<string | undefined>(undefined);
    const [request, setRequest] = useState<string | undefined>(undefined);
    const [output, setOutput] = useState<string | undefined>(undefined);
    const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);
    const [selectedPractices, setSelectedPractices] = useState<string[]>([]);
    const [lastChat, setLastChat] = useState("");
    const profile = getProfile(user?.profile)
    const promptAssistant = profile?.promptAssistant;
    const basePrompt = `${promptAssistant?.characterText || ""} ${character || ""}, ${promptAssistant?.requestsText || ""} ${request || ""}, ${promptAssistant?.outputText || ""} ${output || ""}`
    const basePromptPractices = `${promptAssistant?.characterText || ""} ${character || ""}, ${promptAssistant?.requestsText || ""} ${request || ""}, ${output ? promptAssistant?.outputText || "" : ""} ${output ? output + "," : ""} ${promptAssistant?.practicesText || ""} ${selectedPractices.join(", ") || ""}`;
    const basePromptIndustries = `${promptAssistant?.characterText || ""} ${character || ""}, ${promptAssistant?.requestsText || ""} ${request || ""}, ${output ? promptAssistant?.outputText || "" : ""} ${output ? output + "," : ""} ${promptAssistant?.industriesText || ""} ${selectedIndustries.join(", ") || ""}`;
    const basePromptFull = `${promptAssistant?.characterText || ""} ${character || ""}, ${promptAssistant?.requestsText || ""} ${request || ""}, ${output ? promptAssistant?.outputText || "" : ""} ${output ? output + "," : ""} ${promptAssistant?.practicesText || ""} ${selectedPractices.join(", ") || ""} ${promptAssistant?.industriesText || ""} ${selectedIndustries.join(", ") || ""}`;
    useEffect(() => {
        if (!chatPrompt && open)
            setChatPrompt(lastChat)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    useEffect(() => {
        if (open && selectedPractices.length >= 1 && selectedIndustries.length <= 0)
            setChatPrompt(basePromptPractices);
        if (open && selectedPractices.length <= 0 && selectedIndustries.length >= 1)
            setChatPrompt(basePromptIndustries);
        if  (open && selectedPractices.length >= 1 && selectedIndustries.length >= 1)
            setChatPrompt(basePromptFull);
        if (open && character && request && output && selectedIndustries.length <= 0 && selectedPractices.length <= 0)
            setChatPrompt(basePrompt);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPractices, selectedIndustries]);

    function onChangeCharacter(collection: SelectChangeEvent<string>): void {
        const previousCharacter = character;
        if (previousCharacter)
            setChatPrompt(chatPrompt.replace(previousCharacter, collection.target.value));
        else
            setChatPrompt(`${promptAssistant?.characterText || ""} ${collection.target.value},`)
        setCharacter(collection.target.value);
    }

    function onChangeRequest(collection: SelectChangeEvent<string>): void {
        const previousRequest = request;
        if (previousRequest)
            setChatPrompt(chatPrompt.replace(previousRequest, collection.target.value));
        else
            setChatPrompt(`${chatPrompt} ${promptAssistant?.requestsText || ""} ${collection.target.value},`)
        setRequest(collection.target.value);

    }

    function onChangeOutput(collection: SelectChangeEvent<string>): void {
        const previousOutput = output;
        if (previousOutput)
            setChatPrompt(chatPrompt.replace(previousOutput, collection.target.value));
        else
            setChatPrompt(`${chatPrompt} ${promptAssistant?.outputText || ""} ${collection.target.value},`)
        setOutput(collection.target.value);
    }

    function handleChatPromptChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void {
        setChatPrompt(event.target.value);
    }

    function onChangePractices(event: SelectChangeEvent<string[]>): void {
        const { target: { value }, } = event;
        // const previousPractices = selectedPractices;
        // console.log(previousPractices)
        // if (previousPractices && previousPractices.length >= 1 && typeof value !== "string") {
        //     previousPractices.forEach(item => {
        //         setChatPrompt(chatPrompt.replace(`${item}`, ""))
        //     })
        //     if (promptAssistant?.practicesText)
        //         setChatPrompt(chatPrompt.replace(promptAssistant.practicesText, `${promptAssistant?.practicesText} ${value.join(", ")}`))
        // }
        // else if (typeof value !== "string") {
        //     if (promptAssistant?.practicesText)
        //         setChatPrompt(`${chatPrompt} ${promptAssistant?.practicesText} ${value.join(", ")}`)
        // }
        setSelectedPractices(
            typeof value === 'string' ? value.split(',') : value,
        );
    }

    function onChangeIndustries(event: SelectChangeEvent<string[]>): void {
        const { target: { value }, } = event;
        setSelectedIndustries(
            typeof value === 'string' ? value.split(',') : value,
        );
    }

    async function handleUseItButton(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        await handleSubmit(event);
        handleClose();
    }

    function handleClose() {
        setOpen(false)
        setLastChat(chatPrompt);
        // setCharacter(undefined);
        // setRequest(undefined);
        // setOutput(undefined);
        // setSelectedPractices([]);
        // setSelectedIndustries([]);
        setChatPrompt("");
    }

    function reset() {
        setCharacter(undefined);
        setRequest(undefined);
        setOutput(undefined);
        setSelectedPractices([]);
        setSelectedIndustries([]);
        setChatPrompt("");
    }

    return (
        <Box>
            <Dialog maxWidth="md" fullWidth open={open} onClose={() => setOpen(false)}>
                <DialogTitle>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6">PROMPT ASSISTANT</Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Grid spacing={2} container direction="row" sx={{ mt: 1 }}>
                        <Grid item md={2} lg={1.5}>
                            <Typography variant="body1" sx={{ mt: 1 }}><b>Character</b></Typography>
                        </Grid>
                        <Grid item md={10} lg={10.5}>
                            <CharacterSelector value={character} breakpoint={breakpoint} onChange={onChangeCharacter} />
                        </Grid>
                        <Grid item md={2} lg={1.5}>
                            <Typography variant="body1" sx={{ mt: 1 }}><b>Request</b></Typography>
                        </Grid>
                        <Grid item md={10} lg={10.5}>
                            <RequestSelector value={request} breakpoint={breakpoint} onChange={onChangeRequest} />
                        </Grid>
                        <Grid item md={2} lg={1.5}>
                            <Typography variant="body1" sx={{ mt: 1 }}><b>Format</b></Typography>
                        </Grid>
                        <Grid item md={10} lg={10.5}>
                            <OutputSelector value={output} breakpoint={breakpoint} onChange={onChangeOutput} />
                        </Grid>
                        <Grid item md={2} lg={1.5}>
                            <Typography variant="body1" sx={{ mt: 1 }}><b>Practices</b></Typography>
                        </Grid>
                        <Grid item md={10} lg={10.5}>
                            <PracticesSelector
                                selectedPractices={selectedPractices}
                                breakpoint={breakpoint}
                                setSelectedPractices={setSelectedPractices}
                                onChange={onChangePractices}
                            />
                        </Grid>
                        <Grid item md={2} lg={1.5}>
                            <Typography variant="body1" sx={{ mt: 1 }}><b>Industry</b></Typography>
                        </Grid>
                        <Grid item md={10} lg={10.5}>
                            <IndustriesSelector
                                selectedIndustries={selectedIndustries}
                                breakpoint={breakpoint}
                                setSelectedIndustries={setSelectedIndustries}
                                onChange={onChangeIndustries}
                            />
                        </Grid>
                    </Grid>
                    <TextField
                        margin="normal"
                        fullWidth
                        multiline
                        variant="standard"
                        value={chatPrompt}
                        onChange={handleChatPromptChange}
                        id="chat-prompt"
                        name="Prompt"
                        InputProps={{ disableUnderline: true }}
                    />
                </DialogContent>
                <DialogActions>
                    <StyledButton sx={{ mb: 1, mr: 2, minWidth: 130 }} onClick={reset}>Reset</StyledButton>
                    <StyledButton sx={{ mb: 1, mr: 2, minWidth: 130 }} onClick={handleClose}>Cancel</StyledButton>
                    <StyledLoadingButton
                        id="email"
                        type="submit"
                        variant="contained"
                        onClick={handleUseItButton}
                        loading={busy}
                        sx={{ mb: 1, mr: 2, minWidth: 130 }}
                    >
                        Use It!
                    </StyledLoadingButton>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
