import React from "react";

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";

import { StyledButton } from "./StyledComponents";

export interface Props {
    message: string;
    title?: string;
    open: boolean;
    onClose: (event: React.SyntheticEvent, confirmed: boolean) => void;
}

export default ({ message, title, open, onClose }: Props) => (
    <Dialog open={open} onClose={event => onClose(event as React.SyntheticEvent, false)}>
        {title && <DialogTitle>{title}</DialogTitle>}

        <DialogContent>
            <DialogContentText>{message}</DialogContentText>
        </DialogContent>

        <DialogActions>
            <StyledButton onClick={event => onClose(event, true)} autoFocus>Yes</StyledButton>
            <StyledButton onClick={event => onClose(event, false)}>No</StyledButton>
        </DialogActions>
    </Dialog>
);
