import { useApp, useUser } from "../context";
import { useDocument } from "./hooks";
import * as firestore from "firebase/firestore";

export interface AskConfig {
    defaultNamespace: string;
    maxPromptHits: number;
    model: string;
    persona: string;
    prompt: string;
    additionalPrompts: string[];
    strictModePrompt: string;
    exactTextModePrompt: string;
    conversationProfiles: ConversationStyleConfig;
    temperature: number;
    topK: number;
}

export interface ConversationStyleProfile {
    temperature: number;
    strictMode: boolean;
    exactTextMode: boolean;
}

export interface ConversationStyleConfig {
    creative: ConversationStyleProfile;
    balanced: ConversationStyleProfile;
    precise: ConversationStyleProfile;
}

export interface ResponsesConfig {
    model: string;
    persona: string;
    prompt: string;
    additionalPrompts: string[];
    temperature: number;
}

export interface RequestsConfig extends ResponsesConfig {
    enabled: boolean;
}

export interface CloudConfig {
    answer: { concurrency: number} ;
    ask: AskConfig;
}

export interface ImporterConfig {
    concurrency: number;
    requests: RequestsConfig;
    responses: ResponsesConfig;
}

export interface Config {
    cloud: CloudConfig;
    importer: ImporterConfig;
}

export function useAdmin() {
    const { user } = useUser();
    const { db } = useApp();

    const [profileCloudDoc] = useDocument(`profiles/${user?.promptAdmin || user?.profile}/config`, "cloud");
    const profileCloud = profileCloudDoc ? { ...profileCloudDoc.data() } as CloudConfig : undefined;
    const [profileImporterDoc] = useDocument(`profiles/${user?.promptAdmin || user?.profile}/config`, "importer");
    const profileImporter = profileImporterDoc ? { ...profileImporterDoc.data() } as ImporterConfig : undefined;

    const [cloudDoc] = useDocument("config", "cloud");
    const cloud = cloudDoc ? { ...cloudDoc.data() } as CloudConfig : undefined;
    const [importerDoc] = useDocument("config", "importer");
    const importer = importerDoc ? { ...importerDoc.data() } as ImporterConfig : undefined;

    const baseConfig = { cloud, importer } as Config;
    const config = { cloud: profileCloudDoc?.exists() ? profileCloud : cloud, importer: profileImporterDoc?.exists() ? profileImporter: importer } as Config;

    async function initialSetup(profile: string, config: ImporterConfig | CloudConfig, docId: "cloud" | "importer") {
        const doc = firestore.doc(db, `profiles/${profile}/config`, docId);
        await firestore.setDoc(doc, { ...config }, { merge: true });
    }

    async function updatePrompt(profile: string, type: string, subType: string, persona?: string, prompt?: string) {
        const doc = firestore.doc(db, `profiles/${profile}/config`, type);
        await firestore.setDoc(doc, { [subType]: {
                persona: persona && persona.length >= 1 ? persona : firestore.deleteField(),
                prompt: prompt && prompt.length >= 1  ? prompt : firestore.deleteField(),
            }
        }, { merge: true });
    }

    async function updateBasePrompt(type: string, subType: string, persona: string, prompt: string) {
        const doc = firestore.doc(db, `config`, type);
        if (type === "cloud")
            await firestore.setDoc(doc, { ask: { persona, prompt } }, { merge: true });
        else
            await firestore.setDoc(doc, { [subType]: { persona, prompt } }, { merge: true });
    }

    async function updateRequestFeature(profile: string, enabled: boolean) {
        const doc = firestore.doc(db, `profiles/${profile}/config`, "importer");
        await firestore.setDoc(doc, { requests: { enabled } }, { merge: true });
    }

    async function updatePromptTokens(profile: string, type: string, subType: string, additionalPrompts?: string[]) {
        const doc = firestore.doc(db, `profiles/${profile}/config`, type);
        if (type === "cloud")
            await firestore.setDoc(doc, { ask: { 
                additionalPrompts: additionalPrompts && additionalPrompts.length >= 1 ? additionalPrompts : firestore.deleteField()
            } }, { merge: true });
        else
            await firestore.setDoc(doc, { [subType]: { 
                additionalPrompts: additionalPrompts && additionalPrompts.length >= 1 ? additionalPrompts : firestore.deleteField()
            } }, { merge: true });
    }

    async function updateModePrompt(profile: string, value: string, prompt: "strictModePrompt" | "exactTextModePrompt") {
        const doc = firestore.doc(db, `profiles/${profile}/config`, "cloud");
        await firestore.setDoc(doc, { ask: { [prompt]: value } }, { merge: true });
    }

    async function updateChatMode(profile: string, value: boolean, conversationProfile: "creative" | "balanced" | "precise", field: "strictMode" | "exactTextMode" | "temperature") {
        const doc = firestore.doc(db, `profiles/${profile}/config`, "cloud");
        await firestore.setDoc(doc, {
            ask: {
                "conversationProfiles": {
                    [conversationProfile]: {
                        [field]: value
                    }
                }
            }
        }, { merge: true });
    }

    async function configureChatSettings(profile: string) {
        const doc = firestore.doc(db, `profiles/${profile}/config`, "cloud");
        const conversationProfiles = {
            creative: {
                temperature: 0.9,
                strictMode: false,
                exactTextMode: false
            },
            balanced: {
                temperature: 0.5,
                strictMode: true,
                exactTextMode: false
            },
            precise: {
                temperature: 0.2,
                strictMode: true,
                exactTextMode: true
            },
        }
        const topK = 25;
        const temperature = 0;
        const defaultNamespace = "ikaun";
        const maxPromptHits = 25;
        const model = "gpt-4";
        const exactTextModePrompt = `You are a simple helper that receives text extracts that could potentially answer the user question and you should just return that, without sentiment or intelligence. Below is a numbered list of possible answers named as "HITS". Each numbered hit consists of a summary with the name of a file in square brackets which is the file where the information came from. Filter out the hits that best answer the question and discard the hits that do not appear to be related to the question. Your response should be the "HIT" text that best fits the question coming from the user, if you need to use more than one "HIT", combine them to formulate the response. You should not use you own words, or your database or internet knowledge to answer the user question. If there are no positive hits found then respond with "No matching documents found.`;
        const strictModePrompt = "Do not embellish, base your answer strictly on the HITS given";
        await firestore.setDoc(doc, { ask: {
            conversationProfiles,
            exactTextModePrompt,
            strictModePrompt,
            topK,
            temperature,
            maxPromptHits,
            defaultNamespace,
            model
        } }, { merge: true });
    }

    return {
        profileCloud,
        profileCloudDoc,
        profileImporter,
        profileImporterDoc,
        cloud,
        cloudDoc,
        importer,
        importerDoc,
        baseConfig,
        config,
        configureChatSettings,
        initialSetup,
        updateChatMode,
        updateModePrompt,
        updatePrompt,
        updateBasePrompt,
        updateRequestFeature,
        updatePromptTokens
    }
}