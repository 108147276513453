import React, { useEffect, useState } from "react";
import { Profile, useProfile, useUser } from "../context";

import {
    FormControl,
    LinearProgress,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography
} from "@mui/material";

import { KeyboardArrowDown as ArrowDownIcon } from "@mui/icons-material"

export interface Props {
    extraProfile?: Profile;
    profile?: string;
    userProfiles?: string[];
    onChange: (collection: SelectChangeEvent<string>, setValue: React.Dispatch<React.SetStateAction<string | undefined>>) => void;
}

export default function CollectionSelector({ extraProfile, profile, userProfiles, onChange }: Props) {
    const { user } = useUser();
    const { profiles } = useProfile();
    const [value, setValue] = useState<string | undefined>(profile);
    const profilesArray = userProfiles?.map(profs => {
        return { id: profs, name: profs }
    }) as Profile[]
    const IProfiles = userProfiles ? profilesArray : extraProfile ? [extraProfile, ...profiles] : profiles;

    useEffect(() => {
        console.log(value)
        if ((!value || value === "") && user?.profile)
            setValue(user.profile)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.profile])

    if (user)
        return (
            <FormControl>
                <Select
                    value={value || user?.profile}
                    size="small"
                    displayEmpty
                    onChange={(event) => onChange(event, setValue)}
                    IconComponent={ArrowDownIcon}
                    renderValue={(selected) => {
                        if (!selected && !value) {
                            return (
                                <Typography variant="button" sx={{ color: "#3FA2F7" }}>
                                    Collections
                                </Typography>
                            );
                        }
                        else if (selected || value) {
                            return (
                                <Typography variant="button" sx={{ color: "#3FA2F7" }}>
                                    {selected?.replace("cohen", "ikaun-Transactions-2")|| value?.replace("cohen", "ikaun-Transactions-2")}
                                </Typography>
                            );
                        }
                    }}
                    sx={{ minWidth: 10, bgcolor: "white", borderRadius: 6, "& .MuiSelect-icon": { color: "#3FA2F7" } }}
                >
                    <MenuItem disabled value="">
                        <em>Collections</em>
                    </MenuItem>
                    {IProfiles.map(profile => (
                        <MenuItem
                            value={profile.id}
                            selected={value === profile.name}
                        >
                            <Typography sx={{ color: "#3FA2F7" }}>{profile.name.replace("cohen", "ikaun-Transactions-2")}</Typography>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    else
        return (
            <LinearProgress sx={{ mt: 2 }} />
        );
}