import React, { useState } from "react";
import { useUser, FolderType } from "./context";
import { useApp } from "../AppContext";

import {
    SxProps,
    Theme,
    Typography
} from "@mui/material";

import {
    FileUpload as UploadIcon
} from "@mui/icons-material";

import { StyledLoadingButton } from "./StyledComponents";

import {
    createDocument,
    updateDocument
} from "./lib";

const apiUrl = process.env.REACT_APP_API_URL!;

export interface Props {
    folder: FolderType;
    label?: string;
    onComplete?: (event: React.SyntheticEvent) => void;
    onError?: (event: React.SyntheticEvent, message: string) => void;
    sx?: SxProps<Theme>;
}

export default function FileUploadButton({ folder, label = "Upload Documents", onComplete = () => {}, onError = () => {}, sx }: Props) {
    const { user } = useUser();
    const { db } = useApp();
    const [loading, setLoading] = useState(false);

    async function handleUpload(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
        event.preventDefault();
        const files = Array.from(event.target.files!);
        if (files.length > 0 && user?.profile) {
            setLoading(true);
            for (const file of files) {
                const fileName = file.name.replace("PDF", "pdf");
                const collection = `profiles/${user.profile}/documents`;
                const id = await createDocument({
                    db,
                    collection,
                    data: {
                        name: fileName,
                        filename: fileName,
                        folder,
                        status: "uploading",
                        progress: 0,
                        profile: user.profile,
                        createdAt: new Date()
                    }
                });

                const body = new FormData();
                body.append("file", file);
                try {
                    const response = await fetch(
                        `${apiUrl}/profile/${user.profile}/${folder}/${encodeURIComponent(fileName)}/upload?tenant=${user?.tenant}`, {
                            method: "POST",
                            body,
                            headers: { "Content-Type": "multipart/form-data" }
                        });
                    const error = !response.ok ? response.statusText : undefined;
                    onError(event, error || "");
                    await updateDocument({ db, collection, id, data: { status: response.ok ? "pending" : "error", error } });
                }
                catch (err) {
                    const error = err instanceof Error ? err.message : String(err);
                    onError(event, error);
                    await updateDocument({ db, collection, id, data: { status: "error", error } });
                }
            }
            setLoading(false);
            onComplete(event);
        }
    }

    return (
        <StyledLoadingButton
            loading={loading}
            component="label"
            sx={sx}
        >
            <input
                type="file"
                multiple
                accept="application/pdf, image/png, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={handleUpload}
                hidden
            />
            <UploadIcon fontSize="small" sx={{ mr: 1 }} />
            <Typography>{label}</Typography>
        </StyledLoadingButton>
    );
}
